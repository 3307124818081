import {gql} from '@apollo/client';
import {
  RESOURCE_CUSTOMER_PREFIX,
  getBrandId,
  getCustomerId,
  getUpdatedRolesMapResources,
} from '@telia/cpa-web-common/dist/permissions';
import {Button} from '@telia/styleguide';
import React, {FC, useMemo} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';

import userFragment from '../../graphql/fragment/user.graphql';
import deleteUserMutation from '../../graphql/mutation/deleteUser.graphql';
import saveUserMutation from '../../graphql/mutation/saveUser.graphql';
import customerQuery from '../../graphql/query/customer.graphql';
import teliaUsersQuery from '../../graphql/query/teliaUsers.graphql';

import '../management/Permissions.scss';
import './User.scss';

import * as AppRoutes from '../../appRoutes';
import {UseApolloCacheEntityProps, useApolloCacheEntity} from '../../hooks/useApolloCacheEntity';
import {useBrands} from '../../hooks/useBrands';
import {useCustomerOverviews} from '../../hooks/useCustomerOverviews';
import {asEntity, useFormState} from '../../hooks/useFormState';
import {useModal} from '../../hooks/useModal';
import {useMutationWrap} from '../../hooks/useMutationWrap';
import {useResourceRoles} from '../../hooks/useResourceRoles';
import {useUser} from '../../hooks/useUser';
import {getLog} from '../../log';
import {Customer, ID, ResourceRoles, Role, User, UserType} from '../../model';
import {removeLocalFieldsFromUser} from '../../mutationClean';
import {CUSTOMER_USERS_MANAGE, TELIA_USERS_MANAGE} from '../../permissions';
import Loading from '../Loading';
import Form from '../common/Form';
import FormColumn, {FormColumnSizeHalf} from '../common/FormColumn';
import FormRow from '../common/FormRow';
import List from '../common/List';
import ListItem from '../common/ListItem';
import {MobileField} from '../common/MobileField';
import PageSubtitle from '../common/PageSubtitle';
import {FieldWithFormState} from '../common/field';
import {PageViewCounter} from '../metrics/PageViewCounter';
import {BrandRolesTable} from './BrandRolesTable';
import {CustomerRolesTable} from './CustomerRolesTable';

const log = getLog('User', 'INFO');

const customerQueryGql = gql(customerQuery);

interface UserFcProps {
  type: UserType;
  customerId?: ID;
  roles: Role[];
  goBackUrl: string;
}

const teliaUsersQueryGql = gql(teliaUsersQuery);
export const UserFc: FC<UserFcProps> = (props) => {
  const {type, goBackUrl} = props;
  const {customerId, userId} = useParams<{customerId: ID; userId: ID}>() as {customerId: ID; userId: ID};
  const navigate = useNavigate();
  const {formatWithBrand} = AppRoutes.useBrandFormat();
  const {hasPermission, hasCustomerPermission, hasBrandPermission, user, isTelia} = useUser();
  const {showModal} = useModal();
  const {brands} = useBrands();
  const {getName} = useCustomerOverviews();

  const brandOptions = useMemo(() => [{id: '*', name: 'All Brands'}, ...(brands || [])], [brands]);

  const {getBrandRoles} = useResourceRoles();

  const useApolloCacheEntityProps: UseApolloCacheEntityProps = {
    fragment: userFragment,
    entityId: userId,
    newEntity: {
      customerId,
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      rolesMap: customerId ? [{resource: RESOURCE_CUSTOMER_PREFIX + customerId, roles: []}] : [],
      comment: null,
    },
  };
  const formStateOptions = useApolloCacheEntity(useApolloCacheEntityProps);
  const formState = useFormState({
    ...formStateOptions,
    validators: {
      phone: {
        regex: /^\+\d{5,20}$/,
        error: 'Phone number must start with (+) international code and followed by 5 to 20 digits (without spaces)',
      },
    },
  });

  const saveUser = useMutationWrap<{saveUser: User}, {user: User; customerId: ID | undefined; pageType: UserType}>(
    gql(saveUserMutation),
    {refetchQueries: user?.id === userId ? ['user'] : []} // If logged in user is modifying their own user, force update of user query to get updated data
  );

  const onSave = () => {
    const saveUser = formState.entityAs<User>();

    const currAdminRoles =
      user?.rolesMap &&
      brandOptions?.reduce(
        (prev, {id}) => (getBrandRoles(id, user.rolesMap, true).includes('TELIA_ADMIN') ? [...prev, id] : prev),
        []
      );
    const newAdminRoles = brandOptions?.reduce(
      (prev, {id}) => (getBrandRoles(id, saveUser.rolesMap, true).includes('TELIA_ADMIN') ? [...prev, id] : prev),
      []
    );

    const deletingAdminRole = currAdminRoles && newAdminRoles && currAdminRoles.some((b) => !newAdminRoles.includes(b));
    const hasAllBrandAdminRole = newAdminRoles?.includes('*');

    const initialUser = formState.initialEntity as unknown as User;

    // Get all resources that has been newly added to rolesMap, i.e. not present in initial rolesMap
    const addedCustomerIds = getUpdatedRolesMapResources(initialUser.rolesMap, saveUser.rolesMap).filter(
      (updatedResource) =>
        updatedResource.startsWith(RESOURCE_CUSTOMER_PREFIX) && // Only customer resources
        !initialUser.rolesMap?.find(({resource}) => resource === updatedResource) // Not present in initial rolesMap (before editing was started)
    );

    if (addedCustomerIds.length > 0) {
      showModal({
        title: 'Add additional customer user?',
        content: (
          <>
            <p>You are about to give this user access to the following customer(s):</p>
            <List>
              {addedCustomerIds.map((resource) => (
                <ListItem key={resource}>
                  <div>{getName(getCustomerId(resource) || resource)}</div>
                </ListItem>
              ))}
            </List>
            <p>
              Orders of additional access must be sent from the e-mail address of a user with administrator access to
              the customer(s). The order e-mail must be uploaded to the documents section of the customer(s) before
              giving the additional user access to the customer in this web portal.
            </p>
          </>
        ),
        typeToConfirmText: 'confirm',
        confirmText: 'Update',
        confirmType: 'primary',
        onConfirm: onConfirmSave,
      });
    } else if (saveUser.id === user?.id && deletingAdminRole && !hasAllBrandAdminRole) {
      showModal({
        title: 'Delete your own administrator role?',
        content: (
          <div>
            You are about to delete your own administrator role, are you sure you want to continue? You will not be able
            to edit your roles for this brand after this.
          </div>
        ),
        confirmType: Button.kinds.primary,
        confirmText: 'Confirm',
        cancelText: 'Cancel',
        onConfirm: onConfirmSave,
      });
    } else {
      onConfirmSave();
    }
  };

  const onConfirmSave = () => {
    const user = removeLocalFieldsFromUser(formState.entityAs<User>());

    if (!formState.validate()) {
      return;
    }

    if (formState.subEntityAt<ResourceRoles[]>('rolesMap').some(({roles}) => roles.isEmpty())) {
      toast.error('At least one role must be selected for each resource');
      return;
    }

    log.debug('onSave', {user, customerId, type});

    saveUser({
      loadingText: 'Saving user...',
      successText: 'User saved',
      variables: {
        user: {
          ...user,
          type: (user.type ? user.type : props.type) as UserType,
        },
        customerId: customerId,
        pageType: type,
      },
      update: (proxy, {data}) => {
        const {saveUser} = data || {};
        log.debug('saveUserMutation update', saveUser);
        if (saveUser && !formState.entity.id) {
          //  create new user
          if (type === 'CUSTOMER') {
            //  TODO: move the update list of users to parent component?
            const data = proxy.readQuery<{customer: Customer}>({
              query: customerQueryGql,
              variables: {customerId},
            });
            const updatedUsers = [...(data?.customer?.users || []), saveUser];
            log.debug('data', updatedUsers);
            proxy.writeQuery({query: customerQueryGql, data: {customer: {users: updatedUsers}}});
            navigate(
              formatWithBrand(AppRoutes.PROVISIONING_CUSTOMER_USER__customerId_userId, customerId, saveUser.id),
              {replace: true}
            );
          } else if (type === 'TELIA') {
            const data = proxy.readQuery<{teliaUsers: User[]}>({query: teliaUsersQueryGql, variables: {}});
            // data?.teliaUsers.push(saveUser);
            const updatedUsers = [...(data?.teliaUsers || []), saveUser];
            proxy.writeQuery({query: teliaUsersQueryGql, data: {teliaUsers: updatedUsers}});
            navigate(formatWithBrand(AppRoutes.MANAGEMENT_USER__userId, saveUser.id), {replace: true});
          } else {
            log.error('unrecognized user type', type);
          }
        }
      },
    }).then(({data}) => {
      const {saveUser} = data || {saveUser: undefined};
      log.debug('saveUserMutate resolved', saveUser);
      saveUser && formState.onSaved(asEntity(saveUser));
      return saveUser;
    });
  };

  const onDelete = () => {
    log.debug('onDelete');
    const user = formState.entity;
    showModal({
      title: 'Delete user',
      content: <div>{`Are you sure you want to delete user '${user.firstName} ${user.lastName}' ?`}</div>,
      confirmType: Button.kinds.negative,
      confirmText: 'Delete',
      onConfirm: onConfirmDelete,
    });
  };

  const deleteUser = useMutationWrap<{deleteUser: ID}, {userId: ID; customerId: ID; pageType: UserType}>(
    gql(deleteUserMutation)
  );
  const onConfirmDelete = () => {
    log.debug('onConfirmDelete', {userId});
    deleteUser({
      loadingText: 'Deleting user...',
      successText: 'User deleted',
      variables: {
        userId,
        customerId,
        pageType: type,
      },
      update: (proxy, {data}) => {
        const {deleteUser: userId} = data || {};
        log.debug('deleteUserMutation update', userId);
        if (type === UserType.CUSTOMER) {
          //  TODO: move the update list of users to parent component?
          const data = proxy.readQuery<{customer: Customer}>({
            query: customerQueryGql,
            variables: {customerId},
          });
          if (data) {
            const users = data.customer?.users?.filter((user: User) => user.id !== userId);
            proxy.writeQuery({query: customerQueryGql, data: {customer: {...data.customer, users}}, overwrite: true});
          }
        } else if (type === UserType.TELIA) {
          const data = proxy.readQuery<{teliaUsers: User[]}>({query: teliaUsersQueryGql, variables: {}});
          log.debug('data', data);
          if (data) {
            const teliaUsers = data.teliaUsers.filter((user: User) => user.id !== userId);
            proxy.writeQuery({query: teliaUsersQueryGql, variables: {}, data: {teliaUsers}, overwrite: true});
          }
        } else {
          log.error('unrecognized user type', type);
        }
      },
    }).then(({data}) => {
      const {deleteUser} = data || {deleteUser: undefined};
      log.debug('deleteUser resolved', deleteUser);
      navigate(goBackUrl);
    });
  };

  log.debug('props', props);
  const {isEditing, entity, onEdit, onCancel} = formState;
  const isCreating = entity && !entity.id;
  const roleOptions = props.roles || [];

  const isAllowedToManageAll = formState.subEntityAt<ResourceRoles[]>('rolesMap')?.every(({resource}) => {
    if (type === UserType.CUSTOMER) {
      const customerId = getCustomerId(resource);
      return !!(customerId && hasCustomerPermission(CUSTOMER_USERS_MANAGE, customerId));
    }

    const brandId = getBrandId(resource);
    return !!(brandId && hasBrandPermission(CUSTOMER_USERS_MANAGE, brandId));
  });

  return (
    <>
      <PageSubtitle subtitle="User Info" />
      <PageViewCounter page="user" />

      {!entity ? (
        <Loading />
      ) : (
        <Form>
          <FormRow>
            <FormColumn>
              <FieldWithFormState
                formState={formState}
                entityFieldId={'firstName'}
                label="Firstname"
                className="form__field"
              />
              <FieldWithFormState
                formState={formState}
                entityFieldId={'lastName'}
                label="Lastname"
                className="form__field"
              />
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn>
              <FieldWithFormState
                formState={formState}
                entityFieldId={'email'}
                label="Email"
                isEditing={isEditing && isCreating} //  email is un-mutable after creation
                className="form__field"
              />
              <div /> {/* To force field to be half as wide */}
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn>
              <MobileField formState={formState} className="form__field" />
              <div /> {/* To force field to be half as wide */}
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn size={FormColumnSizeHalf}>
              {type === UserType.CUSTOMER ? (
                <CustomerRolesTable formState={formState} roles={roleOptions} />
              ) : (
                <BrandRolesTable formState={formState} roles={roleOptions} />
              )}
            </FormColumn>
          </FormRow>

          {!isEditing ? (
            <div>
              {(type === UserType.TELIA
                ? hasPermission(TELIA_USERS_MANAGE)
                : isTelia()
                ? hasPermission(CUSTOMER_USERS_MANAGE)
                : hasCustomerPermission(CUSTOMER_USERS_MANAGE, customerId)) && (
                <>
                  <Button text="Edit" onClick={onEdit} />
                  {isAllowedToManageAll && <Button text="Delete" onClick={onDelete} kind={Button.kinds.negative} />}
                </>
              )}
              <Link to={goBackUrl}>
                <Button text={'Back to Users'} kind={Button.kinds.cancel} />
              </Link>
            </div>
          ) : (
            <div>
              <Button text={'Save'} onClick={onSave} kind={Button.kinds.primary} />
              <Button text={'Cancel'} onClick={onCancel} kind={Button.kinds.cancel} />
            </div>
          )}
        </Form>
      )}
    </>
  );
};
