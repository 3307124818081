import {OperationVariables, gql, useApolloClient} from '@apollo/client';
import {getFragmentDefinitions} from '@apollo/client/utilities';
import {DocumentNode} from 'graphql';
import React, {useState} from 'react';

import * as AppRoutes from '../appRoutes';
import {getLog} from '../log';
import {ID} from '../model';
import {Entity, FormStateOptions} from './useFormState';

const log = getLog('useApolloCacheEntity', 'INFO');

export interface UseApolloCacheEntityProps {
  fragment: any;
  watchQuery?: DocumentNode;
  watchVariables?: OperationVariables;
  entityId: ID;
  newEntity: Entity;
}

export const useApolloCacheEntity = (props: UseApolloCacheEntityProps): FormStateOptions => {
  const {entityId, newEntity, watchQuery, watchVariables} = props;
  const fragment = gql(props.fragment);

  const fragmentDefinion = getFragmentDefinitions(fragment)[0];
  const fragmentName = fragmentDefinion.name.value;
  const fragmentType = fragmentDefinion.typeCondition.name.value;

  const apolloClient = useApolloClient();
  const [initialEntity, setInitialEntity] = useState<Entity>();

  const readEntityFromCache = () => {
    const id = `${fragmentType}:${entityId}`;
    const cacheEntity = apolloClient.cache.readFragment<Entity>({fragment, fragmentName, id});
    if (cacheEntity) {
      log.debug(`Found '${id}' fragment in apollo client cache`, cacheEntity);
      setInitialEntity(cacheEntity);
    } else {
      log.warn(`No '${id}' fragment in apollo client cache`);
    }
  };

  const isNewEntity = entityId === AppRoutes._CREATE;
  log.debug(isNewEntity ? `Is new ${fragmentType} Entity` : `Entity ID: ${entityId}`);

  if (initialEntity) {
    log.debug('initialEntity already set');
  } else if (isNewEntity) {
    log.debug('Setting initialEntity from newEntity');
    setInitialEntity({...newEntity, id: null});
  } else {
    readEntityFromCache();
    if (watchQuery) {
      apolloClient // Listen to cache changes, and update initialEntity if needed
        .watchQuery({fetchPolicy: 'cache-first', query: watchQuery, variables: watchVariables})
        .subscribe(readEntityFromCache);
    }
  }

  const formStateOptions = {isEditing: isNewEntity, initialEntity};
  log.debug({props, formStateOptions});
  return formStateOptions;
};
