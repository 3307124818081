import classnames from 'classnames';
import React, {FC} from 'react';

import '../management/Permissions.scss';
import './ResourceRolesTable.scss';

import {FormState} from '../../hooks/useFormState';
import {getLog} from '../../log';
import {ID, ResourceRoles, Role, User} from '../../model';
import {Icon} from '../common/Icon';
import Table from '../common/Table';
import TableCell from '../common/TableCell';
import TableRow from '../common/TableRow';
import Tooltip from '../common/Tooltip';
import {Field, FieldTypes} from '../common/field';

const log = getLog('RolesTable', 'INFO');

interface ResourceRolesTable {
  formState: FormState;
  roles: Role[];
  resourceOptions: {id: ID; name: string}[];
  getResourceRoles: (resourceId: ID, rolesMap?: ResourceRoles[], exact?: boolean) => string[];
  addResourceRole: (roleId: ID, resourceId: ID, rolesMap: ResourceRoles[]) => ResourceRoles[];
  removeResourceRole: (roleId: ID, resourceId: ID, rolesMap: ResourceRoles[]) => ResourceRoles[];
  hasResourceEditPermission: (resourceId: ID) => boolean;
  showHeadings?: boolean;
  showDeleteButton?: boolean;
  onRemoveResource?: (resourceId: ID) => void;
}

export const ResourceRolesTable: FC<ResourceRolesTable> = ({
  formState,
  resourceOptions,
  roles,
  getResourceRoles,
  addResourceRole,
  removeResourceRole,
  hasResourceEditPermission,
  showHeadings = true,
  showDeleteButton = false,
  onRemoveResource,
}) => {
  const hasRoleInAny = (roleId: ID) =>
    resourceOptions?.some(({id}) => getResourceRoles(id, formState.entityAs<User>().rolesMap).includes(roleId));

  return (
    <Table id="permissionsTable" className="roles__table">
      <thead>
        <TableRow className="roles__table--row">
          <TableCell isHeader className="label">
            Role
          </TableCell>
          <TableCell isHeader className="roles__table--spacing" />
          {showHeadings &&
            resourceOptions.map((resource) => (
              <TableCell key={resource.id + 'Header'} className="rotate-45" isHeader>
                <div>
                  <span>{resource.name}</span>
                </div>
              </TableCell>
            ))}
        </TableRow>
      </thead>
      <tbody>
        {roles.map((roleOption, i) => (
          <TableRow key={roleOption.id} className="roles__table--row">
            <TableCell
              className={classnames('roles__table--name', 'label', {
                inactive: !hasRoleInAny(roleOption.id),
              })}
            >
              {roleOption.name}
            </TableCell>
            <TableCell className="roles__table--tip">
              <Tooltip text={roleOption.description} />
            </TableCell>
            {resourceOptions.map((resource) => (
              <TableCell key={roleOption.id + resource.id}>
                <Field
                  value={getResourceRoles(resource.id, formState.entityAs<User>().rolesMap, true).includes(
                    roleOption.id
                  )}
                  onChange={(checked) => {
                    const rolesMap = formState.subEntityAt<ResourceRoles[]>('rolesMap');

                    const updatedRolesMap = checked
                      ? addResourceRole(roleOption.id, resource.id, rolesMap)
                      : removeResourceRole(roleOption.id, resource.id, rolesMap);

                    log.debug('updated rolesMap', updatedRolesMap);
                    formState.onChange('rolesMap')(updatedRolesMap as any); //TODO: as any
                  }}
                  type={FieldTypes.checkbox}
                  isEditing={formState.isEditing && hasResourceEditPermission(resource.id)}
                />
              </TableCell>
            ))}
          </TableRow>
        ))}
        <TableRow className="roles__table--row">
          <TableCell />
          <TableCell />
          {showDeleteButton &&
            resourceOptions.map(({id}) => (
              <TableCell>
                {formState.isEditing && hasResourceEditPermission(id) && (
                  <Icon
                    icon="trash"
                    className="redText pointer rem1_25 halfRightMargin"
                    onClick={() => onRemoveResource && onRemoveResource(id)}
                  />
                )}
              </TableCell>
            ))}
        </TableRow>
      </tbody>
    </Table>
  );
};
