import {useLazyQuery} from '@apollo/client';
import {dates, format, period} from '@telia/cpa-web-common';
import {GroupPeriod, ReportUsedSenderId} from '@telia/cpa-web-common/dist/model';
import {Alert, Button, Form, FullWidthTable} from '@telia/styleguide';
import {useThSortable} from '@telia/styleguide/atoms/FullWidthTable/useThSortable';
import gql from 'graphql-tag';
import React, {FC, useMemo} from 'react';

import usedSenderIdsQuery from '../../../graphql/query/usedSenderIds.graphql';

import {useCsv} from '../../../hooks/useCsv';
import {useCustomerOverviews} from '../../../hooks/useCustomerOverviews';
import {Entity, useFormState} from '../../../hooks/useFormState';
import {getLog} from '../../../log';
import Loading from '../../Loading';
import FormColumn from '../../common/FormColumn';
import FormRow from '../../common/FormRow';
import InformationLineFc from '../../common/InformationLine';
import Tooltip from '../../common/Tooltip';
import {FieldTypes, FieldWithFormState} from '../../common/field';
import {PageViewCounter} from '../../metrics/PageViewCounter';

const log = getLog('ReportUsedSenderIds', 'INFO');

interface UsedSenderIdsQuery {
  usedSenderIds: ReportUsedSenderId[];
}

const {THead, TBody, Tr, Td, ThSortable} = FullWidthTable;

export const ReportUsedSenderIds: FC = () => {
  const {getName} = useCustomerOverviews();
  const {downloadAsCsv} = useCsv();

  const formStateOptions = {
    isEditing: true,
    useUrlParams: true,
    preferenceFieldNames: ['startDate', 'endDate', 'groupPeriod', 'caseSensitive'],
  };

  const formState = useFormState({
    ...formStateOptions,
    validators: {
      senderId: {
        onValidate: (senderId) => !!senderId,
        error: 'Sender Id is required',
      },
      startDate: [
        {
          onValidate: (startDate) => !!(startDate && !period.isFuture({startDate: startDate.toString()})),
          error: 'From date must be in the past',
        },
        {
          onValidate: (startDate) => !!(startDate && period.isWithinMonths(startDate?.toString(), 6)),
          error: 'From date must be within 6 months',
        },
        {
          onValidate: (startDate) => !!startDate,
          error: 'From date is required',
        },
      ],
      groupPeriod: {
        onValidate: (groupPeriod) => !!groupPeriod,
        error: 'Aggregate by is required',
      },
    },
  });
  const {entity, putError, onSaved, isEditing, onEdit, onCancel, subEntityAt} = formState;

  const [fetch, {loading, data: {usedSenderIds = undefined} = {}}] = useLazyQuery<UsedSenderIdsQuery>(
    gql(usedSenderIdsQuery),
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only', // Doesn't check cache before making a network request
    }
  );

  const fetchStatistics = () => {
    if (!formState.validate()) return;

    const parameters: Entity = entity || {};

    if (!period.isValid(parameters)) {
      putError('startDate', 'Invalid search period');
      putError('endDate', 'Invalid search period');
      return;
    }

    entity ? onSaved(entity) : onCancel();

    fetch({
      variables: {
        ...parameters,
      },
    });
  };

  const enrichedStatistics = useMemo(
    () =>
      usedSenderIds?.map((stat) => ({
        ...stat,
        customerName: getName(stat.customerId),
        date: dates.formatGroupPeriodDate(stat.date, subEntityAt<GroupPeriod>('groupPeriod')),
      })) || [],
    [usedSenderIds]
  );

  const [sortedStatistics, getThSortableProps] = useThSortable<ReportUsedSenderId & {customerName: string}>(
    enrichedStatistics
  );

  log.debug('render', {loading, usedSenderIds, enrichedStatistics, sortedStatistics});

  return (
    <>
      <PageViewCounter page="used-senderid-statistics" />
      <p className="noMarginTop">
        This report shows the number of messages that has been sent with a certain Sender ID during the specified time
        period.
      </p>
      <Form>
        <FormRow>
          <FormColumn>
            <FieldWithFormState
              formState={formState}
              entityFieldId="startDate"
              label="From date"
              type={FieldTypes.date}
            />
            <FieldWithFormState formState={formState} entityFieldId="endDate" label="To date" type={FieldTypes.date} />
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn>
            <FieldWithFormState
              formState={formState}
              entityFieldId="senderId"
              label="Sender ID"
              type={FieldTypes.input}
            />
            <FieldWithFormState
              formState={formState}
              entityFieldId="groupPeriod"
              label="Aggregate by"
              type={FieldTypes.select}
              tip="The number of sent messages in the report will be grouped by the selected period."
              options={[
                {id: GroupPeriod.DAY, name: 'Day'},
                {id: GroupPeriod.WEEK, name: 'Week'},
                {id: GroupPeriod.MONTH, name: 'Month'},
                {id: GroupPeriod.QUARTER, name: 'Quarter'},
              ]}
            />
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn>
            <FieldWithFormState
              formState={formState}
              entityFieldId="caseSensitive"
              label="Case sensitive search"
              type={FieldTypes.checkbox}
              tip="Case sensitive: Only exact SenderID matches will be returned."
            />
            <div />
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn>
            <div>
              <Button
                onClick={isEditing ? fetchStatistics : onEdit}
                text={isEditing ? 'Fetch used Sender Ids' : 'Edit search'}
                kind={isEditing ? Button.kinds.primary : Button.kinds.normal}
                className="marginTop"
              />
            </div>
          </FormColumn>
        </FormRow>
      </Form>

      {loading && <Loading />}

      {!loading && !isEditing && (
        <>
          {(!subEntityAt('endDate') || dates.isTodayOrFuture(subEntityAt('endDate'))) && (
            <div className="pending__info--container marginTop">
              <Alert kind="warning">
                <p>
                  The search period includes the current day, it might take up to 24 hours before these numbers are
                  accurate.
                </p>
              </Alert>
            </div>
          )}

          {sortedStatistics.length === 0 ? (
            usedSenderIds !== undefined && (
              <InformationLineFc>No messages found with Sender ID: '{entity.senderId?.toString()}'</InformationLineFc>
            )
          ) : (
            <>
              <FullWidthTable>
                <THead>
                  <Tr>
                    <ThSortable {...getThSortableProps('date')}>
                      Period
                      <Tooltip text="Period is based on UTC" className="marginLeft noTooltipPadding" />
                    </ThSortable>
                    <ThSortable {...getThSortableProps('customerName')}>Customer</ThSortable>
                    <ThSortable {...getThSortableProps('accessNumber')}>Access Number</ThSortable>
                    <ThSortable {...getThSortableProps('amount')}>Messages</ThSortable>
                  </Tr>
                </THead>
                <TBody>
                  {sortedStatistics.map(({date, customerName, accessNumber, amount}) => (
                    <Tr key={date + customerName + accessNumber}>
                      <Td>{date}</Td>
                      <Td>{customerName}</Td>
                      <Td>{accessNumber}</Td>
                      <Td>{format.integer(amount)}</Td>
                    </Tr>
                  ))}
                </TBody>
              </FullWidthTable>
              <Button
                text="Download as CSV"
                onClick={() =>
                  downloadAsCsv({
                    header: ['Period', 'Customer', 'Access Number', 'Messages'],
                    content: sortedStatistics.map(({date, customerName, accessNumber, amount}) => [
                      date,
                      customerName,
                      accessNumber,
                      amount,
                    ]),
                    filename: 'used-sender-ids.csv',
                  })
                }
              />
            </>
          )}
        </>
      )}
    </>
  );
};
