import {i18n} from 'dateformat';
import moment from 'moment';

import {Duration, GroupPeriod} from './model';
import './utils';

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
export const monthOptionValues = monthNames.map((monthName, i) => ({id: `${i + 1}`.padStart(2, '0'), name: monthName}));

export const years = Array.range(2018, new Date().getFullYear());

export const yearsFrom = (startYear: number): number[] => Array.range(startYear, new Date().getFullYear());

export const monthMm: (month: string | number | Date) => string = (month) => {
  let monthNumber;
  if (typeof month === 'number') {
    monthNumber = month;
  } else if (month instanceof Date) {
    monthNumber = month.getMonth() + 1;
  } else {
    monthNumber = parseInt(month);
    if (isNaN(monthNumber)) {
      monthNumber = (i18n.monthNames.indexOf(month) % 12) + 1;
    }
  }
  return monthNumber.toString().padStart(2, '0');
};

export const settlementPeriodYear: (yyyyMm: string | number) => string = (yyyyMm) => yyyyMm.toString().substring(0, 4);
export const settlementPeriodMonth: (yyyyMm: string | number) => string = (yyyyMm) => yyyyMm.toString().substring(4, 6);
export const settlementPeriod: (year: string | number, month: string | number) => string = (year, month) =>
  `${year}${monthMm(month)}`;
export const settlementPeriodFromHuman: (humanMonthYear: string) => string = (humanMonthYear) => {
  const [monthName, year] = humanMonthYear.split(' ');
  const mm = monthName !== undefined ? monthMm(monthName) : '00';
  const yyyy = year !== undefined ? year : '0000';
  return settlementPeriod(yyyy, mm);
};
export const dateOfLastDayOfPreviousMonth = () => {
  const date = new Date(); //  today
  date.setDate(0); //  last day of previous month;
  return date;
};
const iso8601DurationRegex =
  /^(-?)P(?=\d|T\d)(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)([DW]))?(?:T(?:(\d+)H)?(?:(\d+)M)?(?:(\d+(?:[.,]\d+)?)S)?)?$/;
export const isValidDuration: (duration: Duration) => boolean = (duration) => !!duration.match(iso8601DurationRegex);

export const nextMonthMm = (mm: string | number): string => {
  const monthIndex = parseInt(mm.toString());
  const nextMonthIndex = monthIndex === 12 ? 1 : monthIndex + 1;
  return nextMonthIndex.toString().padStart(2, '0');
};

/**
 * Assumes the startDate is already set to the start of the group period in yyyy-mm-dd format,
 * and returns a readable string representing the group period with the given start date.
 *
 * @param {string} startDate - The start date in yyyy-mm-dd format.
 * @param {GroupPeriod} [groupPeriod] - The group period (e.g., QUARTER, MONTH, WEEK, DAY).
 * @returns {string} A human-readable representation of the group period.
 */
export const formatGroupPeriodDate = (startDate: string, groupPeriod?: GroupPeriod): string => {
  switch (groupPeriod) {
    case GroupPeriod.QUARTER:
      return `${startDate.substring(0, 7)} - ${moment(startDate).add(2, 'months').format('YYYY-MM')}`;
    case GroupPeriod.MONTH:
      return startDate.substring(0, 7); // yyyy-mm-dd -> yyyy-mm
    case GroupPeriod.WEEK:
      return `${moment(startDate).format('YYYY-MM-DD')} - ${moment(startDate).add(6, 'days').format('YYYY-MM-DD')}`;
    case GroupPeriod.DAY: // For GroupPeriod.DAY keep original timestamp
    default:
      return moment(startDate).format('YYYY-MM-DD');
  }
};

export const isTodayOrFuture = (date: Date): boolean => {
  return moment(date).isSameOrAfter(moment(), 'day');
};
