"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUpdatedRolesMapResources = exports.hasUserCustomerPermission = exports.hasUserBrandPermission = exports.getUserPermissions = exports.getCustomerPermissionsFromRolesMap = exports.getBrandPermissionsFromRolesMap = exports.getCustomerId = exports.getBrandId = exports.RESOURCE_CUSTOMER_DEF = exports.RESOURCE_CUSTOMER_PARAM = exports.RESOURCE_CUSTOMER_PREFIX = exports.RESOURCE_BRAND_DEF = exports.RESOURCE_BRAND_PARAM = exports.RESOURCE_BRAND_PREFIX = exports.RESOURCE_WILDCARD = void 0;
const model_1 = require("./model");
const pathParams_1 = require("./pathParams");
exports.RESOURCE_WILDCARD = '*';
exports.RESOURCE_BRAND_PREFIX = '/brands/';
exports.RESOURCE_BRAND_PARAM = 'brandId';
exports.RESOURCE_BRAND_DEF = exports.RESOURCE_BRAND_PREFIX + ':' + exports.RESOURCE_BRAND_PARAM;
exports.RESOURCE_CUSTOMER_PREFIX = '/customers/';
exports.RESOURCE_CUSTOMER_PARAM = 'customerId';
exports.RESOURCE_CUSTOMER_DEF = exports.RESOURCE_CUSTOMER_PREFIX + ':' + exports.RESOURCE_CUSTOMER_PARAM;
const getBrandId = (resource) => (0, pathParams_1.createParamExtractor)(exports.RESOURCE_BRAND_DEF)(exports.RESOURCE_BRAND_PARAM, resource);
exports.getBrandId = getBrandId;
const getCustomerId = (resource) => (0, pathParams_1.createParamExtractor)(exports.RESOURCE_CUSTOMER_DEF)(exports.RESOURCE_CUSTOMER_PARAM, resource);
exports.getCustomerId = getCustomerId;
const getBrandPermissionsFromRolesMap = (rolesMap, brandId, exact = false) => {
    const groupedPermissions = rolesMap
        ? rolesMap
            .filter((map) => (0, exports.getBrandId)(map.resource) === brandId || (!exact && (0, exports.getBrandId)(map.resource) === exports.RESOURCE_WILDCARD))
            .flatMap((filteredMap) => filteredMap.permissions)
        : [];
    return groupedPermissions.filter((permission, index) => groupedPermissions.indexOf(permission) === index);
};
exports.getBrandPermissionsFromRolesMap = getBrandPermissionsFromRolesMap;
const getCustomerPermissionsFromRolesMap = (rolesMap, customerId, exact = false) => {
    if (!rolesMap)
        return [];
    const groupedPermissions = rolesMap
        .filter(({ resource }) => (0, exports.getCustomerId)(resource) === customerId)
        .flatMap(({ permissions }) => permissions);
    return groupedPermissions.filter((permission, index) => groupedPermissions.indexOf(permission) === index);
};
exports.getCustomerPermissionsFromRolesMap = getCustomerPermissionsFromRolesMap;
const getUserPermissions = (user, brandId) => {
    switch (user === null || user === void 0 ? void 0 : user.type) {
        case model_1.UserType.TELIA:
            return (0, exports.getBrandPermissionsFromRolesMap)(user.rolesMap, brandId);
        case model_1.UserType.CUSTOMER:
            return (0, exports.getCustomerPermissionsFromRolesMap)(user.rolesMap);
        default:
            return [];
    }
};
exports.getUserPermissions = getUserPermissions;
const hasUserBrandPermission = (permission, user, brandId) => (0, exports.getUserPermissions)(user, brandId).includes(permission);
exports.hasUserBrandPermission = hasUserBrandPermission;
const hasUserCustomerPermission = (permission, user, customerId) => (0, exports.getCustomerPermissionsFromRolesMap)(user.rolesMap, customerId).includes(permission);
exports.hasUserCustomerPermission = hasUserCustomerPermission;
const getUpdatedRolesMapResources = (prev, updated) => {
    // If no previous rolesMap is given, all resources in new has been added
    if (!prev)
        return (updated === null || updated === void 0 ? void 0 : updated.map(({ resource }) => resource)) || [];
    // If no updated rolesMap is given, all resources in previous rolesMap has been removed
    else if (!updated)
        return prev.map(({ resource }) => resource) || [];
    // Find entries in rolesMap that has been updated
    const updatedResources = updated.reduce((updatedResources, resourceRoles) => {
        var _a;
        // Find corresponding resource in previous rolesMap
        const prevResourceRoles = ((_a = prev === null || prev === void 0 ? void 0 : prev.find(({ resource }) => resource === resourceRoles.resource)) === null || _a === void 0 ? void 0 : _a.roles) || [];
        // If length of roles lists are not matching or content are not matching, roles list has been changed
        if (prevResourceRoles.length !== resourceRoles.roles.length ||
            resourceRoles.roles.some((role) => !prevResourceRoles.includes(role))) {
            return [...updatedResources, resourceRoles.resource];
        }
        else {
            return updatedResources;
        }
    }, []);
    // Find entries in rolesMap that has been deleted from prev
    const deletedResources = prev.reduce((deletedResources, { resource }) => {
        // If there is no resource in updatedRoles map that matches the one in prev,
        // it has been deleted, include in updated resources list
        if (!updated.some((resourceRoles) => resource === resourceRoles.resource)) {
            return [...deletedResources, resource];
        }
        else {
            return deletedResources;
        }
    }, []);
    return [...updatedResources, ...deletedResources];
};
exports.getUpdatedRolesMapResources = getUpdatedRolesMapResources;
