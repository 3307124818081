"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PRE_PROD_BUCKET = exports.PROD_BUCKET = exports.STAGE_BUCKET = exports.LOCALHOST_BUCKET = exports.PRE_PROD_HOSTS = exports.PRE_PROD_HOST = exports.PRE_PROD_HOST_LEGACY = exports.PROD_HOSTS = exports.PROD_HOST = exports.PROD_HOST_LEGACY = exports.STAGE_HOSTS = exports.STAGE_HOST = exports.STAGE_HOST_LEGACY = exports.LOCALHOST_HOSTS = exports.LOCALHOST_IP = exports.LOCALHOST_DEFAULT = exports.LOCALHOST_HOST = void 0;
// LOCAL hosts
exports.LOCALHOST_HOST = 'localhost';
exports.LOCALHOST_DEFAULT = '0.0.0.0';
exports.LOCALHOST_IP = '127.0.0.1';
exports.LOCALHOST_HOSTS = [exports.LOCALHOST_HOST, exports.LOCALHOST_DEFAULT, exports.LOCALHOST_IP];
// STAGE hosts
exports.STAGE_HOST_LEGACY = 'https://cpa.purplegears.net';
exports.STAGE_HOST = 'https://test.messaging.teliacompany.com';
exports.STAGE_HOSTS = [exports.STAGE_HOST_LEGACY, exports.STAGE_HOST];
// PROD hosts
exports.PROD_HOST_LEGACY = 'https://messaging.telia.no';
exports.PROD_HOST = 'https://messaging.teliacompany.com';
exports.PROD_HOSTS = [exports.PROD_HOST_LEGACY, exports.PROD_HOST];
// PRE_PROD hosts
exports.PRE_PROD_HOST_LEGACY = 'https://pre-prod.messaging.telia.no';
exports.PRE_PROD_HOST = 'https://pre-prod.messaging.teliacompany.com';
exports.PRE_PROD_HOSTS = [exports.PRE_PROD_HOST_LEGACY, exports.PRE_PROD_HOST];
exports.LOCALHOST_BUCKET = 'cpa-stage-customer-documents';
exports.STAGE_BUCKET = 'cpa-stage-customer-documents';
exports.PROD_BUCKET = 'cpa-prod-customer-documents';
exports.PRE_PROD_BUCKET = 'cpa-pre-prod-customer-documents';
