import React, {FC, useMemo} from 'react';

import {useBrands} from '../../hooks/useBrands';
import {FormState} from '../../hooks/useFormState';
import {useResourceRoles} from '../../hooks/useResourceRoles';
import {useUser} from '../../hooks/useUser';
import {Role} from '../../model';
import {TELIA_USERS_MANAGE} from '../../permissions';
import {ResourceRolesTable} from './ResourceRolesTable';

interface BrandRolesTable {
  formState: FormState;
  roles: Role[];
}

export const BrandRolesTable: FC<BrandRolesTable> = (props) => {
  const {brands} = useBrands();
  const {hasBrandPermission} = useUser();
  const {addBrandRole, removeBrandRole, getBrandRoles} = useResourceRoles();

  const brandOptions = useMemo(() => [{id: '*', name: 'All Brands'}, ...(brands || [])], [brands]);

  return (
    <ResourceRolesTable
      {...props}
      resourceOptions={brandOptions}
      addResourceRole={addBrandRole}
      removeResourceRole={removeBrandRole}
      getResourceRoles={(resourceId, rolesMap, exact) => getBrandRoles(resourceId, rolesMap, exact)}
      hasResourceEditPermission={(resourceId) => hasBrandPermission(TELIA_USERS_MANAGE, resourceId)}
    />
  );
};
