import {Key, parse, pathToRegexp, Token} from 'path-to-regexp';

export interface ParamIndexDict {
  [key: string]: number;
}

export type UrlPath = string;

export type ParamExtractor = (paramName: string, pathValue: string) => string | null;

function isKey(token: Token): token is Key {
  return !!(token as Key).name;
}

const getParamIndex: (key: string, dict: ParamIndexDict) => number | undefined = (key, dict) => {
  return dict[key];
};

export const createParamExtractor: (pathDef: UrlPath) => ParamExtractor = (pathDef) => {
  const tokens: Token[] = parse(pathDef);
  const paramIndexDict: ParamIndexDict = tokens.filter(isKey).reduce((paramsMap, {name}, index, e) => {
    return {...paramsMap, [`${name}`]: index + 1};
  }, {} as ParamIndexDict);
  return (paramName, pathValue) => {
    const pathRegExp: RegExp = pathToRegexp(pathDef);
    const pathMatches: RegExpExecArray | null = pathRegExp.exec(pathValue);
    const paramIndex = getParamIndex(paramName, paramIndexDict);
    return pathMatches === null || paramIndex === undefined || pathMatches[paramIndex] === undefined
      ? null
      : pathMatches[paramIndex]!;
  };
};
