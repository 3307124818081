import {format} from '@telia/cpa-web-common';
import {Button, FullWidthTable} from '@telia/styleguide';
import classnames from 'classnames';
import qs from 'qs';
import React, {FC, useMemo} from 'react';

import {specialFieldComparators} from '../../../comparators';
import {CsvCell, CsvData, useCsv} from '../../../hooks/useCsv';
import {useOperators} from '../../../hooks/useOperators';
import {getLog} from '../../../log';
import {Dict, ID, PropsWithClassName, ReportBlockedSenderId, Statistic} from '../../../model';
import {InformationLineFc} from '../../common/InformationLine';
import MessageStatusDisplayFc from '../../common/MessageStatusDisplay';
import Tooltip from '../../common/Tooltip';

const log = getLog('ReportStatisticsTable', 'INFO');

interface ReportStatisticsTableProps extends PropsWithClassName {
  groupBy: ID[];
  loading: boolean;
  statistics: Statistic[] | ReportBlockedSenderId[];
}

export const ReportStatisticsTable: FC<ReportStatisticsTableProps> = (props) => {
  const {loading, groupBy, statistics} = props;
  const {netTypeFromOperatorCode, netTypeFromTrafficFee} = useOperators();
  const {downloadAsCsv} = useCsv();

  const groupedStatistics = useMemo(
    () => statisticsGrouping(statistics, groupBy),
    [statistics, groupBy, netTypeFromOperatorCode, netTypeFromTrafficFee]
  );

  const [sortedStatistics, getThSortableProps] = FullWidthTable.useThSortable<
    Partial<Statistic & ReportBlockedSenderId>
  >(groupedStatistics || [], 3, [], specialFieldComparators);

  log.debug('sortedStatistics', {sortedStatistics});

  return (
    <React.Fragment>
      <div className={'marginTop'}>
        {!loading && (
          <>
            <FullWidthTable>
              <FullWidthTable.THead>
                <FullWidthTable.Tr>
                  {groupBy.includes('date') && (
                    <FullWidthTable.ThSortable {...getThSortableProps('date')}>Date</FullWidthTable.ThSortable>
                  )}
                  {groupBy.includes('month') && (
                    <FullWidthTable.ThSortable {...getThSortableProps('month')}>Date</FullWidthTable.ThSortable>
                  )}
                  {groupBy.includes('customerName') && (
                    <FullWidthTable.ThSortable {...getThSortableProps('customerName')} className="noWrap">
                      Customer
                    </FullWidthTable.ThSortable>
                  )}
                  {groupBy.includes('contractId') && (
                    <FullWidthTable.ThSortable {...getThSortableProps('contractId')}>
                      Contract
                    </FullWidthTable.ThSortable>
                  )}
                  {groupBy.includes('senderId') && (
                    <FullWidthTable.ThSortable {...getThSortableProps('senderId')} className="noWrap">
                      Sender ID
                    </FullWidthTable.ThSortable>
                  )}
                  {groupBy.includes('accessNumber') && (
                    <FullWidthTable.ThSortable {...getThSortableProps('accessNumber')} className="noWrap">
                      Access Nr.
                    </FullWidthTable.ThSortable>
                  )}
                  {groupBy.includes('contractType') && (
                    <FullWidthTable.ThSortable {...getThSortableProps('contractType')}>
                      Cnt. Type
                    </FullWidthTable.ThSortable>
                  )}
                  {groupBy.includes('productType') && (
                    <FullWidthTable.ThSortable {...getThSortableProps('productType')} className="noWrap">
                      Service Type
                    </FullWidthTable.ThSortable>
                  )}
                  {groupBy.includes('messageType') && (
                    <FullWidthTable.ThSortable {...getThSortableProps('messageType')}>
                      Msg. Type
                    </FullWidthTable.ThSortable>
                  )}
                  {groupBy.includes('resolution') && (
                    <FullWidthTable.ThSortable {...getThSortableProps('resolution')}>
                      Resolution
                    </FullWidthTable.ThSortable>
                  )}
                  {groupBy.includes('status') && (
                    <FullWidthTable.ThSortable {...getThSortableProps('status')}>Status</FullWidthTable.ThSortable>
                  )}
                  {groupBy.includes('operatorCode') && (
                    <FullWidthTable.ThSortable {...getThSortableProps('operatorCode')} className="noWrap">
                      MNO
                    </FullWidthTable.ThSortable>
                  )}
                  {groupBy.includes('netType') && (
                    <FullWidthTable.ThSortable {...getThSortableProps('netType')}>Net</FullWidthTable.ThSortable>
                  )}
                  <FullWidthTable.ThSortable {...getThSortableProps('messages')}>Messages</FullWidthTable.ThSortable>
                  <FullWidthTable.ThSortable {...getThSortableProps('segments')}>Segments</FullWidthTable.ThSortable>
                </FullWidthTable.Tr>
              </FullWidthTable.THead>
              <FullWidthTable.TBody>
                {sortedStatistics &&
                  sortedStatistics.map((statistic, i) => (
                    <FullWidthTable.Tr key={i}>
                      {groupBy.includes('date') && (
                        <FullWidthTable.Td className="noWrap">{statistic.date}</FullWidthTable.Td>
                      )}
                      {groupBy.includes('month') && (
                        <FullWidthTable.Td className="noWrap">{statistic.month}</FullWidthTable.Td>
                      )}
                      {groupBy.includes('customerName') && (
                        <FullWidthTable.Td>{statistic.customerName}</FullWidthTable.Td>
                      )}
                      {groupBy.includes('contractId') && <FullWidthTable.Td>{statistic.contractId}</FullWidthTable.Td>}
                      {groupBy.includes('senderId') && <FullWidthTable.Td>{statistic.senderId}</FullWidthTable.Td>}
                      {groupBy.includes('accessNumber') && (
                        <FullWidthTable.Td>{statistic.accessNumber}</FullWidthTable.Td>
                      )}
                      {groupBy.includes('contractType') && (
                        <FullWidthTable.Td>{statistic.contractType}</FullWidthTable.Td>
                      )}
                      {groupBy.includes('productType') && (
                        <FullWidthTable.Td>{statistic.productType}</FullWidthTable.Td>
                      )}
                      {groupBy.includes('messageType') && (
                        <FullWidthTable.Td>{statistic.messageType}</FullWidthTable.Td>
                      )}
                      {groupBy.includes('resolution') && (
                        <FullWidthTable.Td>
                          <span
                            className={classnames(
                              ['Expired', 'Unknown'].includes(statistic.resolution || '')
                                ? 'greyText'
                                : statistic.resolution === 'Rejected'
                                ? 'redText'
                                : statistic.resolution === 'Delivered'
                                ? 'greenText'
                                : undefined
                            )}
                          >
                            {statistic.resolution}
                          </span>
                          {statistic.resolution === 'Unknown' && (
                            <Tooltip text="Legacy 'MT Sent' statistics data where the resolution is Unknown." />
                          )}
                        </FullWidthTable.Td>
                      )}
                      {groupBy.includes('status') && (
                        <FullWidthTable.Td>
                          <MessageStatusDisplayFc status={statistic.status}></MessageStatusDisplayFc>
                        </FullWidthTable.Td>
                      )}
                      {groupBy.includes('operatorCode') && (
                        <FullWidthTable.Td className="noWrap">{statistic.operatorCode}</FullWidthTable.Td>
                      )}
                      {groupBy.includes('netType') && <FullWidthTable.Td>{statistic.netType}</FullWidthTable.Td>}
                      <FullWidthTable.Td className="noWrap">{format.integer(statistic.messages)}</FullWidthTable.Td>
                      <FullWidthTable.Td className="noWrap">{format.integer(statistic.segments)}</FullWidthTable.Td>
                    </FullWidthTable.Tr>
                  ))}
              </FullWidthTable.TBody>
            </FullWidthTable>
            {statistics && statistics.isEmpty() ? (
              <InformationLineFc>No statistics found</InformationLineFc>
            ) : (
              <Button
                text="Download as CSV"
                onClick={() => downloadAsCsv({...convertToCsvData(sortedStatistics, groupBy), filename: 'report.csv'})}
              />
            )}
          </>
        )}
      </div>
    </React.Fragment>
  );
};

export const convertToCsvData = (statistics: Partial<Statistic & ReportBlockedSenderId>[], groupBy: ID[]): CsvData => {
  const header = [] as CsvCell[];
  groupBy.includes('date') && header.push('Date');
  groupBy.includes('month') && header.push('Date');
  groupBy.includes('customerName') && header.push('Customer');
  groupBy.includes('contractId') && header.push('Contract');
  groupBy.includes('senderId') && header.push('Sender ID');
  groupBy.includes('accessNumber') && header.push('Access Nr');
  groupBy.includes('contractType') && header.push('Contract Type');
  groupBy.includes('productType') && header.push('Service Type');
  groupBy.includes('messageType') && header.push('Message Type');
  groupBy.includes('resolution') && header.push('Resolution');
  groupBy.includes('status') && header.push('Status');
  groupBy.includes('operatorCode') && header.push('MNO');
  groupBy.includes('netType') && header.push('Net');
  header.push('Messages', 'Segments');

  const content: CsvCell[][] = statistics.map((statistic) => {
    const statsRow = [];
    groupBy.includes('date') && statsRow.push(statistic.date);
    groupBy.includes('month') && statsRow.push(statistic.month);
    groupBy.includes('customerName') && statsRow.push(statistic.customerName);
    groupBy.includes('contractId') && statsRow.push(statistic.contractId);
    groupBy.includes('senderId') && statsRow.push(statistic.senderId);
    groupBy.includes('accessNumber') && statsRow.push(statistic.accessNumber);
    groupBy.includes('contractType') && statsRow.push(statistic.contractType);
    groupBy.includes('productType') && statsRow.push(statistic.productType);
    groupBy.includes('messageType') && statsRow.push(statistic.messageType);
    groupBy.includes('resolution') && statsRow.push(statistic.resolution);
    groupBy.includes('status') && statsRow.push(statistic.status);
    groupBy.includes('operatorCode') && statsRow.push(statistic.operatorCode);
    groupBy.includes('netType') && statsRow.push(statistic.netType);
    statsRow.push(statistic.messages || 0, statistic.segments || 0);
    return statsRow;
  });

  return {header, content};
};

export const statisticsGrouping = (
  statistics: Statistic[] | ReportBlockedSenderId[],
  groupBy: ID[]
): Partial<Statistic & ReportBlockedSenderId>[] => {
  const statsFields = ['messages', 'segments', ...groupBy];
  statsFields.includes('month') && statsFields.remove('date');
  log.debug('statsFields', statsFields);

  const simplifiedStats = statistics.clone().map(({...s}) => {
    Object.keys(s)
      .filter((f) => !statsFields.includes(f))
      .forEach((f) => delete s[f as keyof typeof s]);

    return s;
  });
  log.debug('simplifiedStats', simplifiedStats);

  const groupedStatistics = simplifiedStats.reduce((res, {messages, segments, ...s}) => {
    const comboId = qs.stringify(
      Object.keys({...s})
        .filter((f) => groupBy.includes(f))
        .sort()
        //  FIXME: any
        .reduce((partial: any, key) => {
          partial[key] = {...s}[key as keyof typeof s];

          return partial;
        }, {})
    );
    const partialS = res[comboId];
    if (!partialS) {
      res[comboId] = {...s, messages, segments};
    } else {
      res[comboId] = {...s, messages: messages + partialS.messages, segments: segments + partialS.segments};
    }

    return res;
  }, {} as Dict<Statistic | ReportBlockedSenderId>);

  return Object.values(groupedStatistics);
};
