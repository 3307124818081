import {Tooltip} from '@telia/styleguide';
import React, {FC, useState} from 'react';

import {ID} from '../../model';
import FormColumn, {FormColumnSizeHalf, FormColumnSizeNormal} from '../common/FormColumn';
import FormRow from '../common/FormRow';
import Page from '../common/Page';
import PageSubtitle from '../common/PageSubtitle';
import {ToggleSwitch, ToggleSwitchState} from '../common/ToggleSwitch';
import {SmppSummaryTables} from '../management/SmppSummaryTables';
import {PageViewCounter} from '../metrics/PageViewCounter';
import SmppConnectionsTable from './SmppConnectionsTable';

interface SmppConnectionsProps {
  customerId?: ID;
}

export const SmppConnections: FC<SmppConnectionsProps> = ({customerId}) => {
  const [active, setActive] = useState<ToggleSwitchState>(
    customerId ? ToggleSwitchState.RIGHT : ToggleSwitchState.LEFT
  );

  return (
    <Page>
      <FormRow>
        <FormColumn size={FormColumnSizeNormal}>
          <PageSubtitle>
            {active === ToggleSwitchState.LEFT && !customerId ? (
              <>Smpp summary</>
            ) : (
              active === ToggleSwitchState.RIGHT && (
                <>
                  Smpp connections
                  <Tooltip text="Click on cell to view full value" />
                </>
              )
            )}
          </PageSubtitle>
          <PageViewCounter page="smpp-connections" />
        </FormColumn>
        {!customerId && (
          <FormColumn size={FormColumnSizeHalf}>
            <ToggleSwitch
              active={active}
              leftLabel="Summary view"
              rightLabel="Detailed view"
              leftOnClickHandler={() => setActive(ToggleSwitchState.LEFT)}
              rightOnClickHandler={() => setActive(ToggleSwitchState.RIGHT)}
            />
          </FormColumn>
        )}
      </FormRow>

      {active === ToggleSwitchState.LEFT && !customerId ? (
        <>
          <p>List of services configured with SMPP protocol and summaries of their connection status.</p>
          <SmppSummaryTables />
        </>
      ) : (
        active === ToggleSwitchState.RIGHT && (
          <>
            <p>List of active SMPP connection details.</p>
            <SmppConnectionsTable customerId={customerId} />
          </>
        )
      )}
    </Page>
  );
};
