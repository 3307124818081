function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import { useRef, useState, useEffect } from 'react';
/**
 * Handle internal focus state for TextField to set appropriate style when input is focued.
 * Add focus handling to the event handlers
 */
export var useFocus = function useFocus(props) {
  var localRef = useRef(null);
  var inputRef = props.inputRef || localRef;
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    focus = _useState2[0],
    setFocus = _useState2[1];
  useEffect(function () {
    if (inputRef.current) {
      if (focus) {
        inputRef.current.focus();
      } else {
        inputRef.current.blur();
      }
    }
  }, [focus, inputRef.current]);
  var onFocus = function onFocus(e) {
    setFocus(true);
    if (props.onFocus) {
      props.onFocus(e);
    }
  };
  var onBlur = function onBlur(e) {
    setFocus(false);
    if (props.onBlur) {
      props.onBlur(e);
    }
  };
  var onKeyDown = function onKeyDown(e) {
    if (props.onKeyDown) {
      props.onKeyDown(e);
    }
    if (e.keyCode === 9) {
      e.stopPropagation();
    }
  };
  return {
    inputRef: inputRef,
    focus: focus,
    setFocus: setFocus,
    onFocus: onFocus,
    onBlur: onBlur,
    onChange: props.onChange,
    onKeyDown: onKeyDown
  };
};