import {gql, useLazyQuery} from '@apollo/client';
import {period} from '@telia/cpa-web-common';
import {Button} from '@telia/styleguide';
import React, {FC, FormEvent, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

import detailedMessagesQuery from '../../graphql/query/detailedMessages.graphql';

import * as AppRoutes from '../../appRoutes';
import {useCpaStatusCodes} from '../../hooks/useCpaStatusCodes';
import {useCustomerOverviews} from '../../hooks/useCustomerOverviews';
import {FormStateOptions, useFormState} from '../../hooks/useFormState';
import {useMessageTypes} from '../../hooks/useMessageTypes';
import {useUser} from '../../hooks/useUser';
import {useUserCustomer} from '../../hooks/useUserCustomer';
import {getLog} from '../../log';
import {ID, ReportMessage, ReportMessagesResponse} from '../../model';
import Loading from '../Loading';
import {FormFc} from '../common/Form';
import FormColumn, {FormColumnSizeDouble} from '../common/FormColumn';
import FormRow from '../common/FormRow';
import PageSubtitle from '../common/PageSubtitle';
import {Field, FieldTypes, FieldWithFormState} from '../common/field';
import {PageViewCounter} from '../metrics/PageViewCounter';
import {ReportMessagesTable} from './ReportMessagesTable';

const log = getLog('ReportMessages', 'INFO');

const {isValid} = period;

interface DetailedMessagesQuery {
  detailedMessages: ReportMessagesResponse;
}

interface FetchParams {
  startDate?: string;
  endDate?: string;
  startTime?: string;
  endTime?: string;
  accessNumber?: string;
  cref?: string;
  customerId?: string;
  da?: string;
  oa?: string;
  messageType?: string;
  status?: string;
}

export const ReportMessagesFc: FC = (props) => {
  const {isCustomer, isTelia, user, customerId} = useUser();
  const {customerName} = useUserCustomer();
  const {loading: loadingCustomers, customerOverviews, getName} = useCustomerOverviews();
  const {messageTypeDictionary} = useMessageTypes();
  const [detailedMessages, setDetailedMessages] = useState<ReportMessage[]>();
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<ID>();
  const [isFetchingMore, setIsFetchingMore] = useState<boolean>(false);
  const {statusCodes} = useCpaStatusCodes();
  const {formatWithBrand} = AppRoutes.useBrandFormat();

  const formStateOptions: FormStateOptions = {
    isEditing: true,
    useUrlParams: true,
    preferenceFieldNames: ['startDate', 'endDate', 'customerId'],
    fixedFields: isCustomer() && customerId ? {customerId} : {},
  };
  const formState = useFormState(formStateOptions);

  const [fetch, {loading, data: {detailedMessages: detailedMessagesResponse = {}} = {}, refetch, fetchMore}] =
    useLazyQuery<DetailedMessagesQuery>(gql(detailedMessagesQuery), {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only', // Doesn't check cache before making a network request
    });

  useEffect(() => {
    log.debug('effect for detailedMessagesResponse', {detailedMessagesResponse, loading});
    setDetailedMessages(
      isFetchingMore || (!loading && detailedMessagesResponse?.items) ? detailedMessagesResponse.items : undefined
    );
    setLastEvaluatedKey(
      !loading && detailedMessagesResponse?.lastEvaluatedKey ? detailedMessagesResponse.lastEvaluatedKey : undefined
    );
  }, [detailedMessagesResponse, loading]);

  const getDetailedMessagesParameters = () => {
    let parameters = {...formState.entity};
    formState.clearErrors();
    if (!parameters.startDate) {
      formState.putError('startDate', 'Start date is required');
      return undefined;
    }
    if (!parameters.endDate) {
      formState.putError('endDate', 'End date is required');
      return undefined;
    }
    if (!isValid(parameters)) {
      formState.putError('startDate', 'Invalid search period');
      formState.putError('endDate', 'Invalid search period');
      return undefined;
    }

    const dateTimeParametersTransformer = ({startDate, startTime, endDate, endTime, ...params}: FetchParams) => {
      return {
        startDate: new Date(`${startDate}T${startTime ? startTime : '00:00'}:00.000`).toISOString(),
        endDate: new Date(`${endDate}T${endTime ? endTime : '23:59'}:59.999`).toISOString(),
        limit: 200, //  TODO: if the search queries return a smaller projection (without events) this limit can be increased to 500+
        ...params,
      };
    };

    return dateTimeParametersTransformer(parameters);
  };

  const fetchMessages = () => {
    const parameters = getDetailedMessagesParameters();
    log.debug('Fetch messages', parameters);
    setDetailedMessages(undefined);
    fetch({
      variables: {
        customerId: isCustomer() && user ? customerId : null, //  might be overwritten by parameters
        ...parameters,
        isCustomer: isCustomer(),
      },
    });
  };

  const fetchMoreMessages = async () => {
    const parameters = getDetailedMessagesParameters();
    log.debug('Fetch more messages', parameters);
    setIsFetchingMore(true);
    await fetchMore({
      updateQuery: (previousQueryResult, {fetchMoreResult, variables}) => {
        log.debug('updateQuery', {previousQueryResult, fetchMoreResult, variables});
        const allItems = [
          ...(previousQueryResult?.detailedMessages?.items || []),
          ...(fetchMoreResult?.detailedMessages.items || []),
        ];
        log.trace('all items', allItems);
        return {
          detailedMessages: {
            items: allItems,
            lastEvaluatedKey: fetchMoreResult?.detailedMessages?.lastEvaluatedKey,
            __typename: 'DetailedMessageResponse',
          },
        };
      },
      variables: {
        lastEvaluatedKey, //  set only the parameter which changes
      },
    })
      .then((res) => {
        log.debug('resolved fetch more messages', res);
        setIsFetchingMore(false);
        const {
          loading,
          data: {
            detailedMessages: {items, lastEvaluatedKey},
          },
        } = res;
      })
      .catch((error) => {});
  };

  const {entity} = formState;
  log.debug(
    'render',
    {
      entity,
      customerName,
      customerOverviews,
      detailedMessagesCount: detailedMessages?.length,
      detailedMessages,
      detailedMessagesResponse,
      lastEvaluatedKey,
    },
    document.location.host
  );
  return (
    <React.Fragment>
      <PageSubtitle subtitle="Detailed Messages" />
      <PageViewCounter page="messages" />

      {entity && (
        <FormFc onSubmit={fetchMessages}>
          <FormRow>
            <FormColumn>
              <FieldWithFormState
                formState={formState}
                entityFieldId={'startDate'}
                label="From date"
                type={FieldTypes.date}
                defaultValue={'since ever'}
              />
              <FieldWithFormState
                formState={formState}
                entityFieldId={'startTime'}
                label="From time"
                type={FieldTypes.time}
                defaultValue={'Start of day'}
              />
            </FormColumn>
            <FormColumn>
              <FieldWithFormState
                formState={formState}
                entityFieldId={'endDate'}
                label="To date"
                type={FieldTypes.date}
                defaultValue={entity.inheritsEndDate ? (entity.endDate as string) : 'no end'}
              />
              <FieldWithFormState
                formState={formState}
                entityFieldId={'endTime'}
                label="To time"
                type={FieldTypes.time}
                defaultValue={'End of day'}
              />
            </FormColumn>
            <FormColumn>
              {isTelia() && (
                <FieldWithFormState
                  formState={formState}
                  entityFieldId={'customerId'}
                  label="Customer"
                  options={customerOverviews}
                  isNullable={true}
                  type={FieldTypes.select}
                  isDisabled={loadingCustomers}
                  onChangeAlso={() => formState.onChange('contractId')(null)}
                />
              )}
              {isCustomer() && user && <Field label="Customer" value={customerName} isEditing={false} />}
              {!user && (
                <FieldWithFormState
                  formState={formState}
                  entityFieldId={'customerId'}
                  label="Customer"
                  isDisabled={true}
                />
              )}

              <FieldWithFormState
                formState={formState}
                entityFieldId={'cref'}
                label="Customer ref."
                placeholder={'all references'}
                type={FieldTypes.input}
              />
            </FormColumn>
            <FormColumn>
              <FieldWithFormState
                formState={formState}
                entityFieldId={'oa'}
                label="Origin Address"
                placeholder={'all addresses'}
                type={FieldTypes.input}
                tip={
                  <div>
                    The senders address:
                    <ul>
                      <li>A text string</li>
                      <li>Short number</li>
                      <li>MSISDN (+47..)</li>
                    </ul>
                  </div>
                }
              />
              <FieldWithFormState
                formState={formState}
                entityFieldId={'da'}
                label="Destination Address"
                placeholder={'all addresses'}
                type={FieldTypes.input}
                error={undefined}
                tip={
                  <div>
                    The receiver address:
                    <ul>
                      <li>Short mumber</li>
                      <li>MSISDN (+47..)</li>
                    </ul>
                  </div>
                }
              />
            </FormColumn>

            {isTelia() && (
              <>
                <FormColumn>
                  <FieldWithFormState
                    formState={formState}
                    entityFieldId={'accessNumber'}
                    label="Access Number"
                    type={FieldTypes.input}
                  />
                  <FieldWithFormState
                    formState={formState}
                    entityFieldId={'messageType'}
                    label="Message Type"
                    type={FieldTypes.select}
                    options={messageTypeDictionary}
                    isNullable={true}
                  />
                </FormColumn>

                <FormColumn>
                  <FieldWithFormState
                    formState={formState}
                    entityFieldId={'status'}
                    label="Status"
                    type={FieldTypes.select}
                    options={statusCodes}
                    isNullable={true}
                  />
                  <div className={'redText'}>
                    <br />
                    This row only for Telia users
                  </div>
                </FormColumn>
              </>
            )}
          </FormRow>

          <FormRow>
            <FormColumn size={FormColumnSizeDouble}>
              <div id="fetchMessages">
                <Button
                  onClick={fetchMessages}
                  text={'Fetch messages'}
                  kind={Button.kinds.primary}
                  className={'marginTop marginRight'}
                />
              </div>
            </FormColumn>
            <FormColumn size={FormColumnSizeDouble}>
              <div id="goToFetchMessageById">
                <Link to={formatWithBrand(AppRoutes.REPORT_MESSAGE__messageId, AppRoutes._EMPTY)}>
                  <Button
                    // onClick={() => push(formatWithBrand(AppRoutes.REPORT_MESSAGE__messageId, AppRoutes._EMPTY))}
                    text={'Fetch message by ID'}
                    className={'marginTop marginRight'}
                  />
                </Link>
              </div>
            </FormColumn>
          </FormRow>
        </FormFc>
      )}
      {lastEvaluatedKey && !loading && (
        <Button onClick={fetchMoreMessages} text={'Fetch more'} className={'marginRight'} />
      )}
      {!loading && detailedMessages && <span>Showing {detailedMessages.length} messages</span>}
      {loading && detailedMessages && <Loading />}
      <div />
      {detailedMessages && <ReportMessagesTable messages={detailedMessages} />}
      {loading && <Loading />}
      {lastEvaluatedKey && !loading && (
        <Button onClick={fetchMoreMessages} text={'Fetch more'} className={'marginRight'} />
      )}
      {!loading && detailedMessages && <span>Showing {detailedMessages.length} messages</span>}
    </React.Fragment>
  );
};
