import {Menu} from '@telia/styleguide';
import React, {FC} from 'react';
import {useLocation} from 'react-router-dom';
import {Link} from 'react-router-dom';

import * as AppRoutes from '../../appRoutes';
import {useCustomerOverviews} from '../../hooks/useCustomerOverviews';
import {useUser} from '../../hooks/useUser';
import {getLog} from '../../log';
import {CustomerOverview, UserAccessStatusType} from '../../model';
import {ALL_CUSTOMERS_READ, CUSTOMER_STATISTICS_REPORT} from '../../permissions';
import teliaLogoReversed from '../../resources/images/telia-logo-reversed.png';
import teliaLogo from '../../resources/images/telia-logo.png';
import {currentEnvironment, isProd} from '../../utils/host';

const log = getLog('Header', 'INFO');

interface MenuItem {
  text: string;
  url?: string;
  subLinks?: MenuItem[];
}

const findActiveMenuLink = (menuLinks: MenuItem[], pathname: string) =>
  menuLinks.reduce((res, link, i) => (res >= 0 ? res : link.url && pathname.startsWith(link.url) ? i : res), -1);

const commonMenuLinks: MenuItem[] = [
  {text: 'Documents', url: AppRoutes.DOCUMENTS},
  {text: 'Support', url: AppRoutes.SUPPORT},
];

const customSortOrder = ['Provisioning', 'Settlement', 'Report', 'Management', 'Documents', 'Support', 'Customer:'];

export const sortMenuItems = (a: MenuItem, b: MenuItem) => {
  const getSortIndex = (text: string) => customSortOrder.findIndex((item) => text.startsWith(item));

  const indexA = getSortIndex(a.text);
  const indexB = getSortIndex(b.text);

  return indexA - indexB;
};

const formatCustomerName = (customer?: CustomerOverview) => `${customer?.name} (${customer?.countryId})`;

export const HeaderFc: FC = (_) => {
  const {formatWithBrand} = AppRoutes.useBrandFormat();
  const location = useLocation();
  const {
    user,
    hasPermission,
    isTelia,
    isCustomer,
    loadingUser,
    userBrands,
    currentUserBrand,
    customerId: userCustomerId,
    userCustomerIds,
  } = useUser();
  const {loading: loadingCustomer, getCustomerOverview} = useCustomerOverviews({skipQuery: !currentUserBrand});

  const menuLinks: MenuItem[] = [...commonMenuLinks];

  const menuHeadings: MenuItem[] =
    userBrands && userBrands.length > 0
      ? userBrands.map(({id, name}) => ({
          text: name,
          url: `/brand/${id}?=location#${encodeURI(window.location.href)}`,
        }))
      : [
          {
            text: 'Hidden heading for logged out',
            url: `/`,
          },
        ];
  if (!currentUserBrand && userBrands && userBrands?.length > 1) {
    menuLinks.push({text: 'Please select a Brand from the list above.', url: '/'});
  } else if (user) {
    if (isTelia()) {
      if (user.userAccessStatusType === UserAccessStatusType.APPLYING) {
        menuLinks.push({
          text: 'Request Access',
          url: AppRoutes.REQUEST_ACCESS,
        });
      }
      if (hasPermission(ALL_CUSTOMERS_READ)) {
        menuLinks.push({
          text: 'Customers',
          url: formatWithBrand(AppRoutes.PROVISIONING),
        });
        menuLinks.push({
          text: 'Settlement',
          url: formatWithBrand(AppRoutes.SETTLEMENT),
        });
        menuLinks.push({text: 'Report', url: formatWithBrand(AppRoutes.REPORT)});
        menuLinks.push({
          text: 'Management',
          url: formatWithBrand(AppRoutes.MANAGEMENT),
        });
      }
    }

    if (isCustomer() && userCustomerId) {
      menuLinks.push({
        text: 'Customer',
        url: formatWithBrand(AppRoutes.PROVISIONING_CUSTOMER__customerId, userCustomerId),
      });
      hasPermission(CUSTOMER_STATISTICS_REPORT) &&
        menuLinks.push({
          text: 'Report',
          url: formatWithBrand(AppRoutes.REPORT),
        });
      if (!loadingCustomer && userCustomerIds && userCustomerIds.length > 1) {
        menuLinks.push({
          text: `Customer: ${formatCustomerName(getCustomerOverview(userCustomerId))}`,
          subLinks: userCustomerIds
            .filter((customerId) => customerId != userCustomerId)
            .map((customerId) => ({
              text: formatCustomerName(getCustomerOverview(customerId)),
              url: `${AppRoutes.format(AppRoutes.USERCUSTOMER_customerId, customerId)}?=location#${encodeURI(
                window.location.href
              )}`,
            })),
        });
      }
    }

    // Sort the menuLinks based on the custom order
    menuLinks.sort(sortMenuItems);
  }

  log.debug('render', {user, userBrands, currentUserBrand, menuHeadings, menuLinks});

  return (
    <header>
      <Menu
        menuLinks={menuHeadings?.map(({text, url}) => ({
          heading: {
            text,
            url,
          },
          links: menuLinks,
        }))}
        buttonValues={{
          login: 'LOG IN',
          myPage: 'MY PROFILE',
          frontPage: 'Front page',
          closeButton: 'Close',
        }}
        activeLinkIndex={findActiveMenuLink(menuLinks, location.pathname)}
        activeIndex={menuHeadings.reduce((x, {text}, i) => (text === currentUserBrand?.name ? i : x), 0)}
        loginUrl={AppRoutes.LOGIN}
        isLoggedIn={!!user}
        myPageUrl={user && AppRoutes.USER}
        logoImageDesktopPath={teliaLogo}
        logoImageInverseDesktopPath={teliaLogoReversed}
        logoTitle={'Telia Bulk Messaging'}
        logoUrl={AppRoutes.HOME}
        linkTemplate={({url, ...otherProps}) => <Link to={url} {...otherProps} />}
        isLoading={loadingUser || loadingCustomer}
      />
      {!isProd() && <div className="envText">Env: {currentEnvironment()}</div>}
    </header>
  );
};
