import React, {FC, useEffect} from 'react';
import {useLocation, useParams} from 'react-router-dom';

import {useCustomerOverviews} from '../../hooks/useCustomerOverviews';
import {usePreferences} from '../../hooks/usePreferences';
import {getLog} from '../../log';
import {ID} from '../../model';
import Loading from '../Loading';

const log = getLog('UserCustomer', 'DEBUG');

export const UserCustomer: FC = () => {
  const {customerId} = useParams<{customerId: ID}>() as {customerId: ID};
  const {hash} = useLocation();
  const {getCustomerOverview} = useCustomerOverviews();
  const {setPreference, getPreference} = usePreferences(['customerId', 'brandId']);
  let redirectUrl = decodeURI(hash.substr(1));
  useEffect(() => {
    const currCustomerId: string | undefined = getPreference('customerId');
    const currBrandId: string | undefined = getPreference('brandId');
    const {brandId} = getCustomerOverview(customerId) || {};
    currCustomerId &&
      currBrandId &&
      (redirectUrl = redirectUrl
        .replace(currCustomerId.toLowerCase(), customerId.toLowerCase())
        .replace(currBrandId.toLowerCase(), brandId?.toLowerCase() || currBrandId.toLowerCase())); //Inject new customer into URL
    setPreference('customerId', customerId);
    window.location.href = redirectUrl;
  }, [customerId]);
  log.debug('render', {customerId, hash});
  return <Loading />;
};
