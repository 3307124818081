import {
  CountriesDocuments,
  Country,
  CountryDocuments,
  Document,
  ID,
  NO,
  SE,
  allCountries,
} from '@telia/cpa-web-common/dist/model';

import {realHost} from '../utils/host';

interface UseDocuments {
  countriesDocuments: CountriesDocuments;
  getCountryDocuments: (countryId?: ID) => CountryDocuments;
}

const DOCUMENT_PREFIX = `Telia_Bulk_Messaging_Agreement`;
const ORDER_FORM_PREFIX = `Order_Form_Telia_Bulk_Messaging`;
const PDF = '.pdf';
const DOCX = '.docx';
const XLSX = '.xlsx';

const BULK_MESSAGING_AGREEMENT: Document = {
  name: 'Bulk Messaging agreement',
  url: `${DOCUMENT_PREFIX}`,
  extension: PDF,
};
const DATA_PROTECTION_ROLES: Document = {
  name: 'Bulk Messaging product - Data protection roles',
  url: 'Bulk_Messaging_product_Data_protection_roles',
  extension: PDF,
};
const APPENDIX_1: Document = {
  name: 'Appendix 1: General Terms and Conditions for Bulk Messaging Services',
  url: `${DOCUMENT_PREFIX}_APPENDIX_1_General_Terms_and_Conditions_for_Bulk_Messaging_Services`,
  extension: PDF,
};
const APPENDIX_2: Document = {
  name: 'Appendix 2: Contact information',
  url: `${DOCUMENT_PREFIX}_APPENDIX_2_Contact_Information`,
  extension: PDF,
};
const APPENDIX_3: Document = {
  name: 'Appendix 3: Product Summary',
  url: `${DOCUMENT_PREFIX}_APPENDIX_3_Product_Summary`,
  extension: PDF,
};
const APPENDIX_4: Document = {name: 'Appendix 4: Fees', url: `${DOCUMENT_PREFIX}_APPENDIX_4_Fees`, extension: PDF};
const APPENDIX_4_2: Document = {
  name: 'Appendix 4: Fees valid from 2025-07-01',
  url: `${DOCUMENT_PREFIX}_APPENDIX_4_Fees_2`,
  extension: PDF,
};
const APPENDIX_5: Document = {
  name: 'Appendix 5: Data Processing Agreement',
  url: `${DOCUMENT_PREFIX}_APPENDIX_5_Data_Processing_Agreement`,
  extension: PDF,
};
const APPENDIX_6: Document = {
  name: 'Appendix 6: Service Specific Terms and Conditions for SenderID Protection',
  url: `${DOCUMENT_PREFIX}_APPENDIX_6_Service_Specific_Terms_and_Conditions_for_SenderID_Protection`,
  extension: PDF,
};

const ORDER_FORM_BULK_SMS: Document = {
  name: 'Bulk SMS',
  url: `${ORDER_FORM_PREFIX}_Bulk_SMS`,
  extension: XLSX,
};
const ORDER_FORM_RBM: Document = {
  name: 'RBM',
  url: `${ORDER_FORM_PREFIX}_RBM`,
  extension: XLSX,
};
const ORDER_FORM_SENDERID: Document = {
  name: 'SMS SenderID Protection',
  url: `${ORDER_FORM_PREFIX}_SMS_SenderID_Protection`,
  extension: XLSX,
};

const NO_DOCUMENTS: Document[] = [
  BULK_MESSAGING_AGREEMENT,
  APPENDIX_1,
  APPENDIX_2,
  APPENDIX_3,
  APPENDIX_4,
  APPENDIX_5,
  APPENDIX_6,
];

const SE_DOCUMENTS: Document[] = [
  {...BULK_MESSAGING_AGREEMENT, extension: DOCX},
  APPENDIX_1,
  {...APPENDIX_2, extension: DOCX},
  APPENDIX_3,
  APPENDIX_4,
  APPENDIX_4_2,
  DATA_PROTECTION_ROLES,
];

const SE_ORDER_FORMS: Document[] = [ORDER_FORM_BULK_SMS, ORDER_FORM_RBM, ORDER_FORM_SENDERID];

const buildDocumentUrl = ({name, url, extension}: Document, countryId: ID, host: string) => ({
  name,
  url: `${host}/documents/commercial/${countryId.toLowerCase()}/cpa_agreement/${url}${extension}`,
});

export const getDocuments = (countries: Country[], host: string) => {
  let countriesDocuments: CountriesDocuments = {} as CountriesDocuments;

  countries.forEach(({id}) => {
    const countryId = id as keyof typeof allCountries;
    switch (countryId) {
      case NO.id:
        countriesDocuments[countryId] = {
          documents: NO_DOCUMENTS.map((document) => buildDocumentUrl(document, id, host)),
          orderForms: [],
        };
        break;
      case SE.id:
        countriesDocuments[countryId] = {
          documents: SE_DOCUMENTS.map((document) => buildDocumentUrl(document, id, host)),
          orderForms: SE_ORDER_FORMS.map((orderForm) => buildDocumentUrl(orderForm, id, host)),
        };
        break;
      default:
        countriesDocuments[countryId] = {
          documents: [],
          orderForms: [],
        };
        break;
    }
  });

  return countriesDocuments;
};

export const useDocuments = (): UseDocuments => {
  const host = realHost();

  const countriesDocuments = getDocuments(Object.values(allCountries), host);

  const isKeyOfCountryList = (key: string): key is keyof typeof allCountries => {
    return key in countriesDocuments;
  };

  const getCountryDocuments = (countryId?: ID) =>
    countryId && isKeyOfCountryList(countryId) ? countriesDocuments[countryId] : {documents: [], orderForms: []};

  return {countriesDocuments, getCountryDocuments};
};
