import {userComparator} from '@telia/cpa-web-common/dist/sort';
import {UserType} from '@telia/cpa-web-common/src/model';
import React, {FC, useMemo} from 'react';
import {useParams} from 'react-router-dom';

import * as AppRoutes from '../../appRoutes';
import {domId} from '../../dom';
import {useResourceRoles} from '../../hooks/useResourceRoles';
import {useRoles} from '../../hooks/useRoles';
import {useUser} from '../../hooks/useUser';
import {getLog} from '../../log';
import {ID, User} from '../../model';
import Loading from '../Loading';
import {InformationLineFc} from '../common/InformationLine';
import List from '../common/List';
import ListItem from '../common/ListItem';

const log = getLog('UserList');

interface UserListFcProps {
  users: User[];
  onSelectUser?: (user: User) => void;
  usersBaseUrl?: string;
  customerId?: string;
}

type RouteParams = {
  customerId?: ID;
};

export const UserListFc: FC<UserListFcProps> = (props) => {
  const routeParams = useParams<RouteParams>() as RouteParams;
  const customerId = routeParams.customerId || props.customerId;

  log.debug('props', props);
  const {users, onSelectUser, usersBaseUrl} = props;
  const {loading, getRole} = useRoles();
  const {currentUserBrand} = useUser();
  const {getBrandRoles, getCustomerRoles} = useResourceRoles();
  const sortedUsers = useMemo(() => users.slice().sort(userComparator), [users]);
  return loading ? (
    <Loading />
  ) : sortedUsers && !sortedUsers.isEmpty() ? (
    <List id="usersList">
      {sortedUsers.map((user) => (
        <ListItem
          id={domId(user.id, 'user')}
          key={user.id}
          link={usersBaseUrl && AppRoutes.join(usersBaseUrl, user.id)}
          onClick={onSelectUser && (() => onSelectUser(user))}
        >
          {user.firstName || user.lastName ? (
            <div className="bold">
              {user.firstName} {user.lastName}
            </div>
          ) : (
            <div className="bold missing">no name</div>
          )}
          <div>
            <span className="labelPrefix">Email </span> {user.email}
          </div>
          <div>
            <span className="labelPrefix">Roles </span>{' '}
            {(user.type === UserType.TELIA
              ? getBrandRoles(currentUserBrand?.id, user.rolesMap)
              : getCustomerRoles(customerId || '', user.rolesMap)
            )
              .map((roleId) => getRole(roleId)?.name || roleId)
              .join(', ')}
          </div>
        </ListItem>
      ))}
    </List>
  ) : (
    <InformationLineFc>No users found</InformationLineFc>
  );
};
