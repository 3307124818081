import {Dict, IdAndName} from '@telia/cpa-web-common/dist/model';

import {getLog} from '../log';

const log = getLog('useCpaStatusCodes', 'INFO');

export interface UseCpaStatusCodes {
  getStatusDescription: (code: number) => string;
  statusCodes: IdAndName[];
}

const getStatusDescription: (code: number) => string = (code) => {
  log.debug('cpaStatusCodeDict[code]: ' + cpaStatusCodeDict[code], {code, cpaStatusCodeDict});
  return cpaStatusCodeDict[code] ? cpaStatusCodeDict[code] : `${code}`;
};

export const useCpaStatusCodes: () => UseCpaStatusCodes = () => {
  return {getStatusDescription, statusCodes};
};

const cpaStatusCodeDict: Dict<string> = {
  // Success
  '200': 'Success',

  // Integrity errors
  '9001': 'Duplicate client reference',

  // Input errors
  '9101': 'Invalid destination address',
  '9102': 'Invalid originating address',
  '9108': 'Blocked destination address',
  '9109': 'Blocked originating address',
  '9110': 'Invalid delivery time',

  // Subscriber errors
  '9201': 'Subscriber unknown',
  '9202': 'Subscriber temporarily barred',
  '9203': 'Subscriber permanently barred',
  '9204': 'Subscriber reserved',
  '9207': 'Refused by service provider',

  // Payload errors
  '9401': 'Invalid payload',

  // Reserved OA
  '9501': 'Reserved Sender ID',
  '9502': 'Blocked Sender ID look-a-like',

  // Other errors
  '9901': 'Unspecified error',
  '9902': 'Throttled - status rate exceeded',
  '9903': 'Invalid validity period',
  '9904': 'Message queue full',
  '9999': 'Expired',

  //  Notify errors
  '10105': 'Missing endpoint configuration',
  '10103': 'Endpoint error',
  '10101': 'Endpoint connection error',
};

const statusCodes: IdAndName[] = Object.keys(cpaStatusCodeDict)
  .filter((key) => key.length < 5)
  .map((key) => ({
    id: key,
    name: `${key}: ${getStatusDescription(parseInt(key))}`,
  }));
