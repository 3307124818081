import {ApolloError, ApolloQueryResult, OperationVariables, gql, useMutation, useQuery} from '@apollo/client';
import {getCustomerId, hasUserBrandPermission, hasUserCustomerPermission} from '@telia/cpa-web-common/dist/permissions';
import {UserType} from '@telia/cpa-web-common/src/model';
import React, {
  Dispatch,
  DispatchWithoutAction,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';

import logoutMutation from '../graphql/mutation/logout.graphql';
import customerQuery from '../graphql/query/customer.graphql';
import customerOverviewsQuery from '../graphql/query/customerOverviews.graphql';
import userQuery from '../graphql/query/user.graphql';

import {getLog} from '../log';
import {Brand, Customer, CustomerOverview, Get, ID, User} from '../model';
import {setSentryUser} from '../sentry';
import {useBrands} from './useBrands';
import {usePreferences} from './usePreferences';
import {useResourceRoles} from './useResourceRoles';
import {useRoles} from './useRoles';

const log = getLog('useUser', 'INFO');

export interface UseUser {
  user?: User;
  loadingUser: boolean;
  userError?: ApolloError;
  hasPermission: (id: ID) => boolean; //Uses current brand
  hasBrandPermission: (permissionId: ID, brandId: ID) => boolean; //To fetch permission of other than current brand
  hasCustomerPermission: (permissionId: ID, customerId: ID) => boolean;
  hasAnyPermission: (...ids: ID[]) => boolean;
  isCustomer: Get<boolean>;
  isTelia: Get<boolean>;
  logout: DispatchWithoutAction;
  customerId?: ID; //  For Customer user type
  userCustomerIds?: ID[]; //  For customer user type
  customer?: Customer; //  For Customer user type
  userBrands?: Brand[];
  currentUserBrand?: Brand;
  currentBrandRoles: ID[];
  // impersonating aware methods (admin)
  realUser?: User;
  hasRealUserPermission: (id: ID) => boolean;
  isImpersonating: boolean;
  setImpersonatedUser: Dispatch<SetStateAction<User | undefined>>;
  currentBrandRealUserRoles: ID[];
  refetch: (variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<UserQuery>>;
}

export const useUser: () => UseUser = () => {
  const userContext = useContext(UserContext);
  return userContext as UseUser;
};

const UserContext = React.createContext<UseUser | undefined>(undefined);

interface UserQuery {
  user: User;
}

interface CustomerQuery {
  customer: Customer;
}

interface CustomerOverviewsQuery {
  customerOverviews: CustomerOverview[];
}

const userQueryGql = gql(userQuery);

export const UserProvider: FC<PropsWithChildren> = (props) => {
  const {loading: loadingRoles} = useRoles();
  const {
    data: {user: realUser} = {},
    loading: loadingUser,
    refetch,
    error,
  } = useQuery<UserQuery>(userQueryGql, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      log.debug('UserProvider userQuery resolved', data);
      setSentryUser(data.user);
    },
  });

  const [logout] = useMutation<{logoutMutation: {data: {user: {id: ID}}}}>(gql(logoutMutation), {
    onCompleted: () => {
      localStorage.removeItem('x-token');
      window.location.href = '/logout';
    },
    onError: (err: ApolloError) => {
      log.error('logout mutation rejected', err);
    },
  });

  const [impersonatedUser, setImpersonatedUser] = useState<User>();
  const isImpersonating = !!impersonatedUser;

  const user = isImpersonating ? impersonatedUser : realUser;
  const isCustomer = () => !!user && user.type === 'CUSTOMER';
  const isTelia = () => !!user && user.type === 'TELIA';

  const {setPreference, getPreference} = usePreferences(['brandId', 'customerId']);

  const userCustomerIds = useMemo(() => {
    if (!isCustomer()) return undefined;

    const customerIds = user?.rolesMap
      .map(({resource}) => getCustomerId(resource))
      .filter((id) => id !== undefined) as ID[];

    if ((customerIds && customerIds.length === 1) || !getPreference('customerId')) {
      setPreference('customerId', customerIds[0]);
    }

    return customerIds;
  }, [user]);

  const customerId = getPreference('customerId');

  const {data: {customer} = {}, loading: loadingCustomer} = useQuery<CustomerQuery>(gql(customerQuery), {
    variables: {customerId}, //TODO: Handle multiple customerIds
    skip: !isCustomer(),
  });

  const {loading: loadingBrands, brands, getBrand} = useBrands();
  const {getBrandRoles, getCustomerRoles} = useResourceRoles();

  const userBrands: Brand[] | undefined = useMemo(() => {
    const userBrands: Brand[] | undefined = isTelia()
      ? brands?.filter(({id: brandId}) => !user || !getBrandRoles(brandId, user.rolesMap).isEmpty())
      : isCustomer() && customer && getBrand(customer.brandId)
      ? [getBrand(customer.brandId)!]
      : [];
    log.debug('useMemo', {userBrands, brands, user, customer});
    if (userBrands?.length === 1) {
      setPreference('brandId', userBrands[0].id);
    }
    return userBrands;
  }, [user, customer, brands]);

  const currentUserBrand = userBrands?.find((b) => b.id === getPreference('brandId'));

  const {loading: loadingCustomerOverviews, data: {customerOverviews} = {}} = useQuery<CustomerOverviewsQuery>(
    gql(customerOverviewsQuery),
    {
      skip: !currentUserBrand,
    }
  );

  const getCustomerOverview = (customerId: ID) =>
    customerOverviews && customerOverviews.find(({id}) => id === customerId);

  const currentBrandRoles = useMemo(
    () =>
      user
        ? user.type === UserType.CUSTOMER
          ? getCustomerRoles(customerId, user.rolesMap)
          : getBrandRoles(currentUserBrand?.id, user.rolesMap)
        : [],
    [user, currentUserBrand]
  );

  const currentBrandRealUserRoles = useMemo(
    () =>
      realUser
        ? realUser.type === UserType.CUSTOMER
          ? getCustomerRoles(customerId, realUser.rolesMap)
          : getBrandRoles(currentUserBrand?.id, realUser.rolesMap)
        : [],
    [realUser, currentUserBrand]
  );

  const hasPermission = (permissionId: ID, permissionUser = user, brandId = currentUserBrand?.id) => {
    if (!permissionUser) return false;

    return permissionUser.type === UserType.TELIA
      ? hasUserBrandPermission(permissionId, permissionUser, brandId)
      : hasUserCustomerPermission(permissionId, permissionUser, customerId);
  };

  const hasRealUserPermission = (permissionId: ID) => hasPermission(permissionId, realUser);

  const hasAnyPermission = (...permissionIds: ID[]) =>
    permissionIds.some((permissionId) => hasPermission(permissionId));

  const hasBrandPermission = (permissionId: ID, brandId: ID) => hasPermission(permissionId, user, brandId);

  const hasCustomerPermission = (permissionId: ID, customerId: ID) => {
    if (!user) return false;

    if (isCustomer()) return hasUserCustomerPermission(permissionId, user, customerId);

    // For Telia users, we need to check if we have permission to manage the brand of the customer
    const {brandId} = getCustomerOverview(customerId) || {};
    return !!(brandId && hasBrandPermission(permissionId, brandId));
  };

  const userContext: UseUser = {
    user,
    loadingUser: loadingUser || loadingRoles || loadingBrands || loadingCustomer || loadingCustomerOverviews,
    userError: error,
    hasPermission,
    hasAnyPermission,
    hasBrandPermission,
    hasCustomerPermission,
    isCustomer,
    isTelia,
    logout,
    realUser,
    hasRealUserPermission,
    isImpersonating,
    setImpersonatedUser,
    customerId,
    customer,
    userCustomerIds,
    userBrands,
    currentUserBrand,
    currentBrandRoles,
    currentBrandRealUserRoles,
    refetch,
  };

  log.debug('UserProvider', userContext);
  return <UserContext.Provider value={userContext}>{props.children}</UserContext.Provider>;
};

export interface WithPermissionsOrRoles {
  permissions?: ID[];
  roles: ID[];
}
