import {User} from './model';

export const mutationInputClean = (obj: any): any => {
  if (obj && Array.isArray(obj)) {
    return obj.map(mutationInputClean);
  } else if (obj && typeof obj === 'object') {
    const mutableObj = {...obj};
    Object.keys(mutableObj).forEach((key: string) => {
      if (key === '__typename') {
        delete mutableObj[key];
      } else {
        mutableObj[key] = mutationInputClean(mutableObj[key]);
      }
    });
    return mutableObj;
  } else {
    return obj;
  }
};

export const removeLocalFieldsFromUser = (user: User): User => {
  const updatedUser = {...user};
  delete updatedUser.isSSOUser;
  delete updatedUser.mfaOptions;
  updatedUser.rolesMap = user.rolesMap.map(({roles, resource}) => ({roles, resource}));

  return updatedUser;
};
