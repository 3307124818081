#import '../fragment/usedSenderIds.graphql'

query usedSenderIds(
  $startDate: Date
  $endDate: Date
  $senderId: ID
  $groupPeriod: GroupPeriod
  $caseSensitive: Boolean
) {
  usedSenderIds(
    startDate: $startDate
    endDate: $endDate
    senderId: $senderId
    groupPeriod: $groupPeriod
    caseSensitive: $caseSensitive
  ) {
    ...usedSenderIdsFragment
  }
}
