import {ID, Resource, ResourceRoles} from '@telia/cpa-web-common/dist/model';
import {
  RESOURCE_BRAND_PREFIX,
  RESOURCE_CUSTOMER_PREFIX,
  RESOURCE_WILDCARD,
  getBrandId,
} from '@telia/cpa-web-common/dist/permissions';
import {getCustomerId} from '@telia/cpa-web-common/src/permissions';

import {getLog} from '../log';

const log = getLog('useResourceRoles', 'INFO');

interface UseResourceRoles {
  getBrandRoles: (brandId: ID | undefined, rolesMap?: ResourceRoles[] | null, exact?: boolean) => ID[];
  addBrandRole: (roleId: ID, brandId: ID, rolesMap?: ResourceRoles[] | null) => ResourceRoles[];
  removeBrandRole: (roleId: ID, brandId: ID, rolesMap?: ResourceRoles[] | null) => ResourceRoles[];
  getCustomerRoles: (customerId: ID, rolesMap?: ResourceRoles[] | null) => ID[];
  addCustomerRole: (roleId: ID, customerId: ID, rolesMap?: ResourceRoles[] | null) => ResourceRoles[];
  removeCustomerRole: (roleId: ID, customerId: ID, rolesMap?: ResourceRoles[] | null) => ResourceRoles[];
  getCustomerIds: (rolesMap?: ResourceRoles[] | null) => ID[];
}

export const useResourceRoles = () => {
  const isMatch = (param: string, extractedParam: string | null) =>
    extractedParam && (extractedParam === param || extractedParam === RESOURCE_WILDCARD);

  const addBrandRole = (roleId: ID, brandId: ID, rolesMap?: ResourceRoles[] | null): ResourceRoles[] =>
    _addResourceRole(roleId, RESOURCE_BRAND_PREFIX + brandId, rolesMap || []);

  const removeBrandRole = (roleId: ID, brandId: ID, rolesMap?: ResourceRoles[] | null): ResourceRoles[] =>
    _removeResourceRole(roleId, RESOURCE_BRAND_PREFIX + brandId, rolesMap || []);

  const getBrandRoles: (
    brandId: ID | undefined,
    rolesMap?: ResourceRoles[] | null,
    exact?: boolean // If true will only match exact resource and not * brand
  ) => ID[] = (brandId = RESOURCE_WILDCARD, rolesMap, exact = false) =>
    rolesMap
      ? exact
        ? rolesMap?.find(({resource}) => brandId === getBrandId(resource))?.roles || []
        : rolesMap.reduce(
            (prev, {resource, roles}) =>
              isMatch(brandId, getBrandId(resource))
                ? [...prev, ...roles.filter((role) => !prev.includes(role))]
                : prev,
            [] as ID[]
          )
      : [];

  const getCustomerRoles = (customerId: ID, rolesMap?: ResourceRoles[] | null): ID[] => {
    return rolesMap?.find(({resource}) => customerId === getCustomerId(resource))?.roles || [];
  };

  const addCustomerRole = (roleId: ID, customerId: ID, rolesMap?: ResourceRoles[] | null): ResourceRoles[] =>
    _addResourceRole(roleId, RESOURCE_CUSTOMER_PREFIX + customerId, rolesMap || []);

  const removeCustomerRole = (roleId: ID, customerId: ID, rolesMap?: ResourceRoles[] | null): ResourceRoles[] =>
    _removeResourceRole(roleId, RESOURCE_CUSTOMER_PREFIX + customerId, rolesMap || [], true);

  const getCustomerIds = (rolesMap?: ResourceRoles[] | null): ID[] => {
    if (!rolesMap) return [];

    const customerIds = rolesMap
      .filter(({resource}) => resource.startsWith(RESOURCE_CUSTOMER_PREFIX))
      .map(({resource}) => getCustomerId(resource))
      .filter((id) => id !== null) as string[];

    return customerIds;
  };

  const useResourceRoles: UseResourceRoles = {
    getBrandRoles,
    addBrandRole,
    removeBrandRole,
    getCustomerRoles,
    addCustomerRole,
    removeCustomerRole,
    getCustomerIds,
  };

  return useResourceRoles;
};

const _addResourceRole = (roleId: ID, resource: Resource, rolesMap: ResourceRoles[]): ResourceRoles[] => {
  let updatedRolesMap = rolesMap.slice();
  //Look for existing resourceRoles object to add role to
  const resourceRoles = rolesMap.find((map) => map.resource === resource);
  if (resourceRoles) {
    //Only append role if it is not in list
    if (!resourceRoles.roles.includes(roleId)) {
      updatedRolesMap.remove(resourceRoles);
      updatedRolesMap.push({...resourceRoles, roles: [...resourceRoles.roles, roleId]});
    }
  } else {
    updatedRolesMap.push({resource, roles: [roleId]});
  }
  return updatedRolesMap;
};

const _removeResourceRole = (
  roleId: ID,
  resource: Resource,
  rolesMap: ResourceRoles[],
  keepEmptyList?: boolean // If true will keep resource with empty roles list, otherwise resource will be removed if the role removal results in an empty list
): ResourceRoles[] => {
  let updatedRolesMap = rolesMap.slice();
  const resourceRoles = rolesMap.find((map) => map.resource === resource);
  if (resourceRoles) {
    const updatedResourceRoles = {...resourceRoles, roles: resourceRoles.roles.slice().remove(roleId)};
    updatedRolesMap.remove(resourceRoles);

    if (!updatedResourceRoles.roles.isEmpty() || keepEmptyList) {
      updatedRolesMap.push(updatedResourceRoles);
    }
  }
  return updatedRolesMap;
};
