import {RESOURCE_CUSTOMER_PREFIX, getCustomerId} from '@telia/cpa-web-common/dist/permissions';
import {Button} from '@telia/styleguide';
import React, {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';

import {useCustomerOverviews} from '../../hooks/useCustomerOverviews';
import {Entity, FormState} from '../../hooks/useFormState';
import {useModal} from '../../hooks/useModal';
import {useResourceRoles} from '../../hooks/useResourceRoles';
import {useUser} from '../../hooks/useUser';
import {ID, IdAndName, Role, User} from '../../model';
import {ALL_CUSTOMERS_MANAGE, CUSTOMER_USERS_MANAGE} from '../../permissions';
import {PickCustomer} from '../provisioning/PickCustomer';
import {ResourceRolesTable} from './ResourceRolesTable';

interface CustomerRolesTable {
  formState: FormState;
  roles: Role[];
}

export const CustomerRolesTable: FC<CustomerRolesTable> = ({formState, ...props}) => {
  const {customerId} = useParams<{customerId: ID}>() as {customerId: ID};

  const {addCustomerRole, removeCustomerRole, getCustomerRoles, getCustomerIds} = useResourceRoles();
  const {hasPermission, hasCustomerPermission} = useUser();
  const {getCustomerOverview} = useCustomerOverviews();
  const {showModal, hideModal} = useModal();

  const {rolesMap} = formState.entityAs<User>();

  const [addedCustomerIds, setAddedCustomerIds] = useState<ID[]>([]);

  useEffect(() => {
    // Once we stop editing, reset added customers list
    !formState.isEditing && setAddedCustomerIds([]);
  }, [formState.isEditing]);

  // Overly complex sorting logic. We want to sort the customer we are currently viewing first,
  // then the rest alphabetically, then the ones that are newly added in this edit last.
  const customerComparator = useCallback(
    (a: IdAndName, b: IdAndName): number => {
      // customerId is the customer we are currently viewing and should be sorted first
      if (a.id.equalsIgnoreCase(customerId)) return -1; // a is the customer we are currently viewing, sort it before b
      if (b.id.equalsIgnoreCase(customerId)) return 1; // b is the customer we are currently viewing, sort it before a

      // a or b has been added in this edit, keep original order (at the end)
      if ([a.id, b.id].some((id) => addedCustomerIds.includes(id))) return 0;

      // Sort alphabetically
      return a.name.localeCompare(b.name);
    },
    [customerId, addedCustomerIds]
  );

  const customerOptions = useMemo(
    () =>
      (rolesMap || [])
        .filter(({resource}) => resource.startsWith(RESOURCE_CUSTOMER_PREFIX))
        .map(({resource}) => {
          const id = getCustomerId(resource) || '';
          const {name = customerId, countryId} = getCustomerOverview(id) || {};

          return {
            id,
            name: `${name} (${countryId})`,
          };
        })
        .sort(customerComparator),
    [rolesMap, getCustomerOverview, customerComparator]
  );

  const addCustomer = () => {
    const addCustomerModalId = showModal({
      title: 'Pick a customer to add:',
      content: (
        <PickCustomer
          selectedCustomerIds={getCustomerIds(formState.subEntityAt('rolesMap'))}
          onPickCustomer={(customerId) => {
            formState.onChange('rolesMap')([
              ...(formState.subEntityAt<Entity[]>('rolesMap') || []),
              {resource: RESOURCE_CUSTOMER_PREFIX + customerId, roles: [] as ID[]},
            ]);
            setAddedCustomerIds([...addedCustomerIds, customerId]);
            hideModal(addCustomerModalId);
          }}
        />
      ),
    });
  };

  const onDeleteCustomerRoles = (customerId: ID) => {
    formState.onChange('rolesMap')(
      (formState.subEntityAt<Entity[]>('rolesMap') || []).filter(
        ({resource}) => resource && !(resource as string).endsWith(customerId)
      )
    );
  };

  return (
    <>
      <ResourceRolesTable
        {...props}
        resourceOptions={customerOptions}
        formState={formState}
        showHeadings={customerOptions.length > 1}
        showDeleteButton={customerOptions.length > 1}
        addResourceRole={addCustomerRole}
        removeResourceRole={removeCustomerRole}
        getResourceRoles={(customerId, rolesMap) => getCustomerRoles(customerId, rolesMap)}
        hasResourceEditPermission={(customerId) => hasCustomerPermission(CUSTOMER_USERS_MANAGE, customerId)}
        onRemoveResource={(customerId) => onDeleteCustomerRoles(customerId)}
      />
      {formState.isEditing && hasPermission(ALL_CUSTOMERS_MANAGE) && (
        <Button text="Additional customer user" onClick={addCustomer} />
      )}
    </>
  );
};
