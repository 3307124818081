import {gql, useLazyQuery, useQuery} from '@apollo/client';
import {constants, format, model, period} from '@telia/cpa-web-common';
import {Button} from '@telia/styleguide';
import classnames from 'classnames';
import React, {FC, useCallback, useMemo} from 'react';

import customerQuery from '../../../graphql/query/customer.graphql';
import statisticsQuery from '../../../graphql/query/statistics.graphql';

import {useCpaStatusCodes} from '../../../hooks/useCpaStatusCodes';
import {useCustomerOverviews} from '../../../hooks/useCustomerOverviews';
import {Entity, useFormState} from '../../../hooks/useFormState';
import {useMessageTypes} from '../../../hooks/useMessageTypes';
import {useOperators} from '../../../hooks/useOperators';
import {useProductTypes} from '../../../hooks/useProductTypes';
import {useUser} from '../../../hooks/useUser';
import {useUserCustomer} from '../../../hooks/useUserCustomer';
import {getLog} from '../../../log';
import {ContractType, ID, ResolutionId, Statistic} from '../../../model';
import Loading from '../../Loading';
import Form from '../../common/Form';
import FormColumn, {FormColumnSizeDouble} from '../../common/FormColumn';
import FormRow from '../../common/FormRow';
import PageSubtitle from '../../common/PageSubtitle';
import {Field, FieldTypes, FieldWithFormState} from '../../common/field';
import {PageViewCounter} from '../../metrics/PageViewCounter';
import {ReportStatisticsTable} from './ReportStatisticsTable';

const {contractTypes, EXTERNAL_CONTRACT_TYPE, INTERNAL_CONTRACT_TYPE} = constants;

const log = getLog('ReportDeliveryStatistics', 'DEBUG');

const {isValid} = period;

interface StatisticsQuery {
  statistics?: Statistic[];
}

const isRejectedSenderId = (status: string | undefined) => status === '9501' || status === '9502';

export const ReportDeliveryStatistics: FC = (props) => {
  const {user, isTelia, isCustomer, currentUserBrand, customerId} = useUser();
  const {statusCodes} = useCpaStatusCodes();
  const {messageTypeDictionary, getMessageTypeCompactName} = useMessageTypes();

  const formStateOptions = {
    isEditing: true,
    useUrlParams: true,
    preferenceFieldNames: ['startDate', 'endDate', 'productType', 'messageTypes', 'resolutions', 'customerId'],
    fixedFields: isCustomer() ? ({customerId} as Entity) : {},
  };
  const formState = useFormState(formStateOptions);
  const {entity, isEditing, onSaved, onCancel, onEdit, onChange, clearErrors, putError} = formState;
  const {loading: loadingCustomers, customerOverviews, getName} = useCustomerOverviews();
  const {getName: getProductTypeName, getBrandProductTypes} = useProductTypes();
  const {netTypeFromOperatorCode, netTypeFromTrafficFee} = useOperators();
  const {customerName} = useUserCustomer();
  const {
    loading: loadingCustomer,
    error,
    data: {customer} = {},
  } = useQuery(gql(customerQuery), {
    skip: !(isCustomer() || entity.customerId),
    variables: {customerId: user && isCustomer() ? customerId : entity.customerId},
  });

  const productTypes = useMemo(
    () => currentUserBrand && getBrandProductTypes(currentUserBrand?.id),
    [currentUserBrand?.id, getBrandProductTypes]
  );

  const getCustomerName = useCallback(
    (id: ID) => (isCustomer() ? customerName || id : getName(id)),
    [isCustomer, customerName, getName]
  );

  const getResolutionName = (resolutionId?: string): string => {
    const resolution = model.resolutions.find((modelResolution) => modelResolution.id === resolutionId);
    return resolution ? resolution.name : !resolutionId ? 'Unknown' : resolutionId;
  };

  const editStatistics = () => {
    onEdit();
  };

  const [fetch, {loading, data: {statistics = undefined} = {}}] = useLazyQuery<StatisticsQuery>(gql(statisticsQuery), {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only', // Doesn't check cache before making a network request
  });

  const fetchStatistics = () => {
    const parameters: Entity = entity || {};
    clearErrors();
    if (!parameters?.startDate) {
      putError('startDate', 'Start date is required');
      return;
    }
    if (!parameters?.endDate) {
      putError('endDate', 'End date is required');
      return;
    }
    if (!isValid(parameters)) {
      putError('startDate', 'Invalid search period');
      putError('endDate', 'Invalid search period');
      return;
    }

    if (!(parameters?.messageTypes as string[])?.length) {
      putError('messageTypes', 'Please select at least one Message Type');
      return;
    }

    if (!(parameters?.resolutions as string[])?.length) {
      putError('resolutions', 'Please select at least one Resolution');
      return;
    }

    log.info('Fetch statistics', parameters);
    entity ? onSaved(entity) : onCancel();

    fetch({
      variables: {
        customerId: user && isCustomer() ? customerId : null, //  might be overwritten by parameters
        ...parameters,
        status: parameters.status ? parseInt(parameters.status as string) : undefined,
        isCustomer: isCustomer(),
      },
    });
  };

  const enrichStatistics = useCallback(
    (statistics?: Statistic[]): Statistic[] => {
      return (
        statistics?.map(
          ({
            date,
            operatorCode,
            trafficFeeType,
            productType,
            messageType,
            resolution,
            status,
            customerId,
            contractType,
            ...s
          }) => ({
            ...s,
            date,
            customerId,
            operatorCode,
            month: date.substring(0, 7),
            netType:
              currentUserBrand?.id === 'TELIA_NO'
                ? netTypeFromOperatorCode(operatorCode)
                : netTypeFromTrafficFee(trafficFeeType),
            customerName: getCustomerName(customerId),
            productType: getProductTypeName(productType),
            messageType: messageType ? getMessageTypeCompactName(messageType) : '',
            resolution: getResolutionName(resolution),
            status: status,
            contractType: constants.contractTypeName(contractType as ContractType),
          })
        ) || []
      );
    },
    [netTypeFromOperatorCode, getCustomerName, getProductTypeName]
  );

  const enrichedStatistics = useMemo(() => enrichStatistics(statistics), [statistics]);

  log.debug('render', {loading, statistics, enrichedStatistics, isEditing});

  return (
    <>
      <PageSubtitle subtitle="Detailed Statistics" />
      <PageViewCounter page="delivery-statistics" />

      {entity && (
        <Form onSubmit={fetchStatistics}>
          <FormRow>
            <FormColumn>
              <FieldWithFormState
                formState={formState}
                entityFieldId={'startDate'}
                label="From date"
                type={FieldTypes.date}
                defaultValue={'since ever'}
              />
              <FieldWithFormState
                formState={formState}
                entityFieldId={'endDate'}
                label="To date"
                type={FieldTypes.date}
                defaultValue={entity.inheritsEndDate ? (entity.endDate as string) : 'no end'}
              />
            </FormColumn>
            <FormColumn>
              <FieldWithFormState
                formState={formState}
                entityFieldId={'productType'}
                label="Service Type"
                isNullable={true}
                type={FieldTypes.select}
                options={productTypes}
              />
              <div />
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn>
              <FieldWithFormState
                formState={formState}
                entityFieldId={'messageTypes'}
                label="Message Types"
                type={FieldTypes.multi}
                options={messageTypeDictionary}
                isEditing={isEditing}
                tip={
                  <div className="paddingLeft">
                    <ul>
                      <li>
                        <span className="bold">Mobile Terminated:</span> sent TO mobile
                      </li>
                      <li>
                        <span className="bold">Mobile Originated:</span> sent FROM mobile
                      </li>
                    </ul>
                  </div>
                }
              />
              <FieldWithFormState
                formState={formState}
                entityFieldId={'resolutions'}
                label="Resolutions"
                type={FieldTypes.multi}
                isEditing={isEditing && !formState.subEntityAt<number>('status')}
                options={model.resolutions}
                tip={
                  <div className="paddingLeft">
                    <ul>
                      <li>Delivered - Message was successfully delivered (status: 200)</li>
                      <li>Rejected - Message was internally rejected by Telia Bulk Messaging Cloud (status: 9XXX)</li>
                      <li>Expired - Message could not be delivered (status: 9999)</li>
                    </ul>
                  </div>
                }
              />
            </FormColumn>
            <FormColumn>
              {isTelia() && (
                <FieldWithFormState
                  formState={formState}
                  entityFieldId={'status'}
                  label="Status"
                  isNullable={true}
                  type={FieldTypes.select}
                  options={statusCodes}
                  onChangeAlso={(status: string) => {
                    if (status === undefined || status === null) return;
                    const resolutions =
                      status === '200'
                        ? [ResolutionId.Delivered]
                        : status === '9999'
                        ? [ResolutionId.Expired]
                        : [ResolutionId.Rejected];
                    onChange('resolutions')(resolutions);

                    if (isRejectedSenderId(status)) {
                      onChange('messageTypes')(['SMS_MT']);
                    }
                  }}
                />
              )}
              <div />
            </FormColumn>
          </FormRow>

          <FormRow>
            <FormColumn>
              <FieldWithFormState
                formState={formState}
                entityFieldId={'contractType'}
                label="Contract Type"
                options={contractTypes}
                isNullable={true}
                type={FieldTypes.select}
                tip={
                  <ul className={classnames('noMargin', 'noPadding')}>
                    <li>{EXTERNAL_CONTRACT_TYPE} contract get invoiced</li>
                    <li>{INTERNAL_CONTRACT_TYPE} contract do NOT get invoiced</li>
                  </ul>
                }
              />
              {isTelia() && (
                <FieldWithFormState
                  formState={formState}
                  entityFieldId={'customerId'}
                  label="Customer"
                  options={customerOverviews}
                  isNullable={true}
                  type={FieldTypes.select}
                  isDisabled={loadingCustomers}
                  onChangeAlso={() => onChange('contractId')(null)}
                />
              )}
              {isCustomer() && user && <Field label="Customer" value={customerName} isDisabled={true} />}
              {!user && (
                <FieldWithFormState
                  formState={formState}
                  entityFieldId={'customerId'}
                  label="Customer"
                  isDisabled={true}
                />
              )}
            </FormColumn>
            <FormColumn>
              {!user ? (
                <Field isEditing={false} isDisabled={true} label="Contract" placeholder={'Loading user...'} />
              ) : isTelia() && !entity.customerId ? (
                <Field isEditing={false} isDisabled={true} label="Contract" placeholder={'Select customer'} />
              ) : !loadingCustomer && customer ? (
                <FieldWithFormState
                  formState={formState}
                  entityFieldId={'contractId'}
                  label="Contract"
                  options={customer.contracts}
                  isNullable={true}
                  type={FieldTypes.select}
                  isDisabled={!customer.contracts}
                />
              ) : (
                <Field
                  isEditing={false}
                  isDisabled={true}
                  label="Contract"
                  placeholder={loading ? 'Loading contracts...' : error ? 'Something went wrong' : ''}
                  error={error && error.message}
                />
              )}
              <FieldWithFormState
                formState={formState}
                entityFieldId={'accessNumber'}
                label="Access Number"
                placeholder={'all access numbers'}
                // type={FieldTypes.input}
              />
            </FormColumn>
          </FormRow>

          <FormRow>
            <FormColumn size={FormColumnSizeDouble}>
              <div id="fetchStatistics">
                {isEditing ? (
                  <Button
                    onClick={fetchStatistics}
                    text={'Fetch statistics'}
                    kind={Button.kinds.primary}
                    className={'marginTop'}
                  />
                ) : (
                  <Button
                    onClick={editStatistics}
                    text={'Edit search'}
                    kind={Button.kinds.normal}
                    className={'marginTop'}
                  />
                )}
              </div>
            </FormColumn>
          </FormRow>
          {!isEditing && statistics && (
            <FormRow>
              <FormColumn size={FormColumnSizeDouble}>
                <FieldWithFormState
                  label={'Date grouping'}
                  formState={formState}
                  entityFieldId={'dateGrouping'}
                  type={FieldTypes.multi}
                  onChangeAlso={(dateGrouping: ID[]) => {
                    // If 'date' is unchecked also uncheck 'month'
                    if (!dateGrouping.includes('date')) {
                      onChange('dateGrouping')([]);
                    }
                  }}
                  options={
                    entity?.dateGrouping && (entity.dateGrouping as string[]).includes('date')
                      ? [
                          {id: 'date', name: 'Date'},
                          {id: 'month', name: 'Month'},
                        ]
                      : [{id: 'date', name: 'Date'}]
                  }
                  className={'noWrap'}
                  isEditing={true}
                />
                <FieldWithFormState
                  label={'Service grouping'}
                  formState={formState}
                  entityFieldId={'productGrouping'}
                  type={FieldTypes.multi}
                  options={[
                    {id: 'customerName', name: 'Customer'},
                    {id: 'contractId', name: 'Contract'},
                    {id: 'accessNumber', name: 'Access Nr'},
                  ]}
                  className={'noWrap'}
                  isEditing={true}
                />
              </FormColumn>
              <FormColumn size={FormColumnSizeDouble}>
                <FieldWithFormState
                  label={'Types grouping'}
                  formState={formState}
                  entityFieldId={'typesGrouping'}
                  type={FieldTypes.multi}
                  options={[
                    {id: 'contractType', name: 'Contract type'},
                    {id: 'productType', name: 'Service Type'},
                    {id: 'messageType', name: 'Message Types'},
                  ]}
                  className={'noWrap'}
                  isEditing={true}
                />
                <FieldWithFormState
                  label={'Delivery grouping'}
                  formState={formState}
                  entityFieldId={'deliveryGrouping'}
                  type={FieldTypes.multi}
                  options={[
                    {id: 'operatorCode', name: 'MNO'},
                    {
                      id: 'netType',
                      name: 'Net',
                      tip: (
                        <div className="paddingLeft">
                          <ul>
                            <li>
                              <span className="bold">On Net:</span> domestic traffic on operators owned by Telia
                            </li>
                            <li>
                              <span className="bold">Off Net:</span> domestic traffic on operators not owned by Telia
                            </li>
                            <li>
                              <span className="bold">Foreign:</span> off net traffic on unknown operators
                            </li>
                          </ul>
                        </div>
                      ),
                    },
                    {id: 'resolution', name: 'Resolution'},
                    {id: 'status', name: 'Status'},
                  ]}
                  className={'noWrap'}
                  isEditing={true}
                />
              </FormColumn>
            </FormRow>
          )}
        </Form>
      )}

      {loading && <Loading />}
      {!isEditing && enrichedStatistics && (
        <ReportStatisticsTable
          loading={loading}
          statistics={enrichedStatistics}
          groupBy={
            entity &&
            ([] as ID[]).concat(
              (entity.dateGrouping as ID[])?.includes('month')
                ? ([...(entity.dateGrouping as ID[])].remove('date') as ConcatArray<ID>)
                : (entity.dateGrouping as ConcatArray<ID>),
              entity.productGrouping as ConcatArray<ID>,
              entity.typesGrouping as ConcatArray<ID>,
              entity.deliveryGrouping as ConcatArray<ID>
            )
          }
        />
      )}
    </>
  );
};
