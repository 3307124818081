import {ID, Resource, ResourceRoles, User, UserType} from './model';
import {createParamExtractor} from './pathParams';

export const RESOURCE_WILDCARD = '*';
export const RESOURCE_BRAND_PREFIX = '/brands/';
export const RESOURCE_BRAND_PARAM = 'brandId';
export const RESOURCE_BRAND_DEF = RESOURCE_BRAND_PREFIX + ':' + RESOURCE_BRAND_PARAM;

export const RESOURCE_CUSTOMER_PREFIX = '/customers/';
export const RESOURCE_CUSTOMER_PARAM = 'customerId';
export const RESOURCE_CUSTOMER_DEF = RESOURCE_CUSTOMER_PREFIX + ':' + RESOURCE_CUSTOMER_PARAM;

export const getBrandId = (resource: Resource) =>
  createParamExtractor(RESOURCE_BRAND_DEF)(RESOURCE_BRAND_PARAM, resource);

export const getCustomerId = (resource: Resource) =>
  createParamExtractor(RESOURCE_CUSTOMER_DEF)(RESOURCE_CUSTOMER_PARAM, resource);

export const getBrandPermissionsFromRolesMap = (rolesMap?: ResourceRoles[], brandId?: string, exact = false) => {
  const groupedPermissions = rolesMap
    ? rolesMap
        .filter(
          (map) => getBrandId(map.resource) === brandId || (!exact && getBrandId(map.resource) === RESOURCE_WILDCARD)
        )
        .flatMap<string>((filteredMap) => filteredMap.permissions!)
    : [];

  return groupedPermissions.filter((permission, index) => groupedPermissions.indexOf(permission) === index);
};

export const getCustomerPermissionsFromRolesMap = (rolesMap?: ResourceRoles[], customerId?: string, exact = false) => {
  if (!rolesMap) return [];

  const groupedPermissions = rolesMap
    .filter(({resource}) => getCustomerId(resource) === customerId)
    .flatMap<string>(({permissions}) => permissions!);

  return groupedPermissions.filter((permission, index) => groupedPermissions.indexOf(permission) === index);
};

export const getUserPermissions = (user?: User, brandId?: ID): string[] => {
  switch (user?.type) {
    case UserType.TELIA:
      return getBrandPermissionsFromRolesMap(user.rolesMap, brandId);
    case UserType.CUSTOMER:
      return getCustomerPermissionsFromRolesMap(user.rolesMap);
    default:
      return [];
  }
};

export const hasUserBrandPermission = (permission: string, user: User, brandId?: ID) =>
  getUserPermissions(user, brandId).includes(permission);

export const hasUserCustomerPermission = (permission: string, user: User, customerId?: ID) =>
  getCustomerPermissionsFromRolesMap(user.rolesMap, customerId).includes(permission);

export const getUpdatedRolesMapResources = (
  prev: ResourceRoles[] | undefined,
  updated: ResourceRoles[] | undefined
): ID[] => {
  // If no previous rolesMap is given, all resources in new has been added
  if (!prev) return updated?.map(({resource}) => resource) || [];
  // If no updated rolesMap is given, all resources in previous rolesMap has been removed
  else if (!updated) return prev.map(({resource}) => resource) || [];

  // Find entries in rolesMap that has been updated
  const updatedResources = updated.reduce((updatedResources, resourceRoles) => {
    // Find corresponding resource in previous rolesMap
    const prevResourceRoles = prev?.find(({resource}) => resource === resourceRoles.resource)?.roles || [];

    // If length of roles lists are not matching or content are not matching, roles list has been changed
    if (
      prevResourceRoles.length !== resourceRoles.roles.length ||
      resourceRoles.roles.some((role) => !prevResourceRoles.includes(role))
    ) {
      return [...updatedResources, resourceRoles.resource];
    } else {
      return updatedResources;
    }
  }, [] as ID[]);

  // Find entries in rolesMap that has been deleted from prev
  const deletedResources = prev.reduce((deletedResources, {resource}) => {
    // If there is no resource in updatedRoles map that matches the one in prev,
    // it has been deleted, include in updated resources list
    if (!updated.some((resourceRoles) => resource === resourceRoles.resource)) {
      return [...deletedResources, resource];
    } else {
      return deletedResources;
    }
  }, [] as ID[]);

  return [...updatedResources, ...deletedResources];
};
