import {FullWidthTable, Icon, PopUpLine, QuickSearch} from '@telia/styleguide';
import {TBody, THead, Td, Th, Tr} from '@telia/styleguide/atoms/TableWithDropDown/TableWithDropDown';
import classNames from 'classnames';
import React, {FC, useEffect, useMemo, useRef, useState} from 'react';

import {useBrands} from '../../hooks/useBrands';
import {useCountries} from '../../hooks/useCountries';
import {useCustomerOverviews} from '../../hooks/useCustomerOverviews';
import {useShowInactive} from '../../hooks/useShowInactive';
import {useUser} from '../../hooks/useUser';
import {getLog} from '../../log';
import {Overview} from '../../model';
import {CUSTOMER_USERS_MANAGE} from '../../permissions';
import Loading from '../Loading';
import InformationLine from '../common/InformationLine';
import List from '../common/List';
import ListItem from '../common/ListItem';
import {Field, FieldTypes} from '../common/field';

const log = getLog('PickCustomer', 'INFO');

interface PickCustomerProps {
  selectedCustomerIds: string[];
  onPickCustomer: (customerId: string) => void;
}

const getState = (productOverviews: Overview[]) => {
  return productOverviews?.some(({state}) => state === 'ACTIVE')
    ? 'ACTIVE'
    : productOverviews?.some(({state}) => state === 'FUTURE')
    ? 'FUTURE'
    : 'EXPIRED';
};

export const PickCustomer: FC<PickCustomerProps> = ({selectedCustomerIds, onPickCustomer}) => {
  const {loading: loadingCustomerOverviews, allCountryCustomerOverviews} = useCustomerOverviews();
  const {loading: loadingCountries, getName} = useCountries();
  const {loading: loadingBrands, getName: getBrandName} = useBrands();
  const {showInactiveComponent, showInactive} = useShowInactive();
  const {hasBrandPermission} = useUser();

  const [filter, setFilter] = useState<string>('');

  const enrichedOverviews = useMemo(
    () =>
      allCountryCustomerOverviews?.map(({id, name, productOverviews, countryId, brandId}) => ({
        customerId: id,
        isSelected: selectedCustomerIds.includes(id),
        customerName: name,
        state: getState(productOverviews),
        countryName: getName(countryId),
        brandName: getBrandName(brandId),
        brandId,
      })),
    [allCountryCustomerOverviews, selectedCustomerIds]
  );

  const filteredEnrichedOverviews = useMemo(
    () =>
      enrichedOverviews?.filter(
        ({customerName, state, brandId}) =>
          hasBrandPermission(CUSTOMER_USERS_MANAGE, brandId) && // Filter by permission
          customerName.toLowerCase().includes(filter) && // Filter by search string
          (showInactive || state === 'ACTIVE') // Filter 'EXPIRED' and 'FUTURE', unless showInactive is checked
      ),
    [enrichedOverviews, filter, showInactive]
  );

  return (
    <>
      <QuickSearch
        placeholder="Search customers"
        value={filter}
        onChange={({target: {value}}) => setFilter(value.toLowerCase())}
        error={filteredEnrichedOverviews?.length === 0}
        leftContent={<Icon icon="search" />}
      />
      <div className="marginTop marginBottomHalfRem">{showInactiveComponent}</div>
      {loadingCustomerOverviews || loadingCountries || loadingBrands ? (
        <Loading />
      ) : (
        <>
          {filteredEnrichedOverviews?.length === 0 && (
            <InformationLine>
              No customers found with current filter <span className="teliaPurple">{filter}</span>
            </InformationLine>
          )}
          <List>
            {filteredEnrichedOverviews?.map(({customerId, isSelected, customerName, state, brandName}) => (
              <ListItem
                className={classNames({'list__item--selected': isSelected})}
                onClick={() => !isSelected && onPickCustomer(customerId)}
              >
                <div className="displayFlex">
                  <Field type={FieldTypes.checkbox} value={isSelected} />
                  <span className="marginLeft paddingLeft flexGrow">{customerName}</span>
                  <span className={classNames('marginLeft flexBasis10', state.toLowerCase() + 'Period')}>{state}</span>
                  <span className="paddingLeft flexGrow">Brand: {brandName}</span>
                </div>
              </ListItem>
            ))}
          </List>
        </>
      )}
    </>
  );
};
