export type CsvCell = undefined | string | number;

export interface CsvData {
  content: CsvCell[][];
  header: CsvCell[];
}

interface CsvDownloadOptions extends CsvData {
  filename: string;
}

export interface UseCsv {
  convertToCsvString: (data: CsvData) => string;
  downloadAsCsv: (options: CsvDownloadOptions) => void;
}

export const useCsv = (): UseCsv => {
  const convertToCsvString = (data: CsvData): string => {
    return [data.header, ...data.content].map((line) => line.join(';')).join('\r\n');
  };

  const downloadAsCsv = (options: CsvDownloadOptions) => {
    const csv = convertToCsvString(options);
    const blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', options.filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return {convertToCsvString, downloadAsCsv};
};
