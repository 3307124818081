import React, {FC} from 'react';
import {Navigate, Route, Routes, useLocation, useNavigate} from 'react-router-dom';

import * as AppRoutes from '../../../appRoutes';
import {useUser} from '../../../hooks/useUser';
import {getLog} from '../../../log';
import {ALL_CUSTOMERS_READ} from '../../../permissions';
import FormColumn, {FormColumnSizeNormal} from '../../common/FormColumn';
import FormRow from '../../common/FormRow';
import PageSubtitle from '../../common/PageSubtitle';
import {ToggleSwitch} from '../../common/ToggleSwitch';
import {ReportBlockedSenderIds} from './ReportBlockedSenderIds';
import {ReportUsedSenderIds} from './ReportUsedSenderIds';

const log = getLog('ReportSenderId', 'INFO');

export const ReportSenderIds: FC = (props) => {
  const {hasPermission} = useUser();

  const subPath = AppRoutes.useSubPath(AppRoutes.REPORT_SENDER_IDS);
  const {formatWithBrand} = AppRoutes.useBrandFormat();
  const navigate = useNavigate();
  const {pathname} = useLocation();

  const usedSenderIdsPath = formatWithBrand(AppRoutes.REPORT_USED_SENDER_IDS);
  const blockedSenderIdsPath = formatWithBrand(AppRoutes.REPORT_BLOCKED_SENDER_IDS);

  return (
    <>
      <FormRow>
        <FormColumn size={FormColumnSizeNormal}>
          <PageSubtitle subtitle="Detailed Sender IDs" />
        </FormColumn>
        {hasPermission(ALL_CUSTOMERS_READ) && (
          <FormColumn>
            <ToggleSwitch
              {...(pathname === usedSenderIdsPath && {active: 'left'})}
              {...(pathname === blockedSenderIdsPath && {active: 'right'})}
              leftLabel="Used SenderIDs"
              rightLabel="Blocked SenderIDs"
              leftOnClickHandler={() => navigate(usedSenderIdsPath, {replace: true})}
              rightOnClickHandler={() => navigate(blockedSenderIdsPath, {replace: true})}
            />
          </FormColumn>
        )}
      </FormRow>
      <Routes>
        <Route index element={<Navigate replace to={usedSenderIdsPath} />} />
        <Route path={subPath(AppRoutes.REPORT_USED_SENDER_IDS)} element={<ReportUsedSenderIds />} />
        <Route path={subPath(AppRoutes.REPORT_BLOCKED_SENDER_IDS)} element={<ReportBlockedSenderIds />} />
      </Routes>
    </>
  );
};
