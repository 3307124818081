import {gql, useQuery} from '@apollo/client';
import {sort} from '@telia/cpa-web-common';
import {useMemo} from 'react';

import customerOverviewsQuery from '../graphql/query/customerOverviews.graphql';

import {getLog} from '../log';
import {CurrencyCode, CustomerOverview, ID, Overview} from '../model';
import {useBrands} from './useBrands';
import {useProductOverviewTypes} from './useProductOverviewTypes';
import {useUser} from './useUser';

const log = getLog('useCustomerOverviews', 'INFO');

interface CustomerOverviewsQuery {
  customerOverviews: CustomerOverview[];
}

export interface UseCustomerOverviewsProps {
  skipQuery?: boolean;
}

export interface CustomerOverviewsHook {
  loading: boolean;
  customerOverviews: CustomerOverview[] | undefined;
  allCountryCustomerOverviews: CustomerOverview[] | undefined;
  getCustomerOverview: (id: ID) => CustomerOverview | undefined;
  getName: (id: ID) => string;
  getCurrencyCode: (id: ID) => CurrencyCode;
}

export const useCustomerOverviews = (props: UseCustomerOverviewsProps = {}) => {
  const {loadingUser, currentUserBrand, isTelia} = useUser();

  const {loading: loadingOverviews, data: {customerOverviews} = {}} = useQuery<CustomerOverviewsQuery>(
    gql(customerOverviewsQuery),
    {
      skip: props.skipQuery || (!currentUserBrand && !isTelia()), //  customer overview access restriction lift for SenderID Protection customers needing to fetch accessNumbers of allowed sender products
    }
  );

  log.debug('customerOverviews', customerOverviews);

  const {getBrand} = useBrands();

  const {isAccessNumberOverview, isAccessNumberRangeOverview, isReservedOaOverview, isRbmOverview, isGenericOverview} =
    useProductOverviewTypes();

  const sortedOverviews = useMemo(
    () =>
      customerOverviews &&
      [...customerOverviews]
        ?.sort(sort.sortByNameComparator) //Sort customers
        .map(({productOverviews, ...customerOverview}) => ({
          ...customerOverview,
          productOverviews: productOverviews
            .map((overview) =>
              isReservedOaOverview(overview)
                ? {...overview, reservedOas: overview.reservedOas?.slice().sort()}
                : overview
            )
            ?.slice()
            .sort(
              (a: Overview, b: Overview) =>
                sort.productOverviewsComparator(a, b) ||
                (isAccessNumberOverview(a) &&
                  isAccessNumberOverview(b) &&
                  sort.accessNumbersSortComparator(a.accessNumber, b.accessNumber)) ||
                (isAccessNumberRangeOverview(a) &&
                  isAccessNumberRangeOverview(b) &&
                  sort.accessNumbersSortComparator(a.accessNumberRange.from, b.accessNumberRange.from)) ||
                (isReservedOaOverview(a) && isReservedOaOverview(b) && sort.reservedOaOverviewsComparator(a, b)) ||
                (isRbmOverview(a) && isRbmOverview(b) && sort.rbmOverviewsComparator(a, b)) ||
                0
            ),
        })),
    [customerOverviews]
  );

  const brandFilteredSortedOverviews = useMemo(
    () => sortedOverviews && [...sortedOverviews].filter(({brandId}) => brandId === currentUserBrand?.id),
    [sortedOverviews]
  );

  log.debug('sortedCustomerOverviews', sortedOverviews);

  const getCustomerOverview = (customerId: ID) =>
    customerOverviews && customerOverviews.find(({id}) => id === customerId);
  const getName = (customerId: ID) => getCustomerOverview(customerId)?.name || customerId;
  const getCurrencyCode = (customerId: ID) => getBrand(customerId)?.currencyCode || '???';

  const useCustomerOverviewsHook: CustomerOverviewsHook = {
    loading: loadingOverviews || loadingUser,
    customerOverviews: brandFilteredSortedOverviews,
    allCountryCustomerOverviews: sortedOverviews,
    getCustomerOverview,
    getName,
    getCurrencyCode,
  };
  log.debug(useCustomerOverviewsHook);
  return useCustomerOverviewsHook;
};
