"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isValidAddress = exports.isV4CidrAddress = exports.isV4IpAddress = void 0;
const ip_address_1 = require("ip-address");
const isV4IpAddress = (address) => {
    return ip_address_1.Address4.isValid(address) && address.split('.').every((section) => +section < 256);
};
exports.isV4IpAddress = isV4IpAddress;
const isV4CidrAddress = (address) => {
    const [ip, subnet] = address.split(/\/(.*)/s);
    if (ip && subnet) {
        return (0, exports.isV4IpAddress)(ip) && /^([0-9]|[1-2][0-9]|3[0-2])?$/.test(subnet);
    }
    else
        return false;
};
exports.isV4CidrAddress = isV4CidrAddress;
const isValidAddress = (address) => !!address && ((0, exports.isV4IpAddress)(address) || (0, exports.isV4CidrAddress)(address));
exports.isValidAddress = isValidAddress;
