import {period} from '@telia/cpa-web-common';
import {FormattedDateOrNull, FormattedTimeOrNull, PeriodI} from '@telia/cpa-web-common/dist/period';
import deepEqual from 'deep-equal';
import React, {Dispatch, DispatchWithoutAction, useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import * as AppRoutes from '../appRoutes';
import {FieldValue} from '../components/common/field';
import {getLog} from '../log';
import {Dict, ID} from '../model';
import {PutError, useErrors} from './useErrors';
import {usePreferences} from './usePreferences';

const log = getLog('useFormState', 'INFO');

const {isValid} = period;

const START_DATE_FIELD_ID = 'startDate';
const START_TIME_FIELD_ID = 'startTime';
const END_DATE_FIELD_ID = 'endDate';
const END_TIME_FIELD_ID = 'endTime';

export type EntityFieldValue = FieldValue | Entity | Entity[];
export type Entity = Dict<EntityFieldValue | undefined>;

function isEntity(entityFieldValue: EntityFieldValue): entityFieldValue is Entity {
  return (
    entityFieldValue !== null &&
    entityFieldValue !== undefined &&
    !Array.isArray(entityFieldValue) &&
    typeof entityFieldValue === 'object'
  );
}

function isString(entityFieldValue?: EntityFieldValue): entityFieldValue is string {
  return !!entityFieldValue && typeof entityFieldValue === 'string';
}

export function asEntity(obj: object) {
  return obj as unknown as Entity;
}

export interface FormState {
  initialEntity: Entity;
  entity: Entity;
  entityAs: <T>() => T;
  subEntityAt: <T = EntityFieldValue>(fieldPath: string) => T;
  isEditing: boolean;
  isCreating: boolean;
  onSaved: Dispatch<Entity>;
  onCancel: DispatchWithoutAction;
  onEdit: DispatchWithoutAction;
  // onChange: (fieldName: ID) => (newValue: EntityFieldValue) => Partial<FormState>;
  onChange: (fieldName: ID) => (newValue: EntityFieldValue) => void;
  // findError: (key: ID) => string | null | undefined;
  findError: (key: ID) => string | undefined;
  // putError: (key: ID, message: string) => FormState;
  // putError: (key: ID, message: string) => void;
  putError: PutError;
  clearErrors: DispatchWithoutAction;
  validate: () => boolean;
}

interface Validator {
  regex?: RegExp;
  onValidate?: (value?: EntityFieldValue) => boolean;
  error: string;
}

export type Validators = Dict<Validator | Validator[]>;

export interface FormStateOptions {
  initialEntity?: Entity;
  isEditing: boolean;
  useUrlParams?: boolean;
  correctPeriod?: boolean; // True by default
  preferenceFieldNames?: ID[];
  fixedFields?: Entity;
  validators?: Validators;
}

/**
 * Handles updating entity field values for (.) entity-composed entity fields (F.ex: company.address.street)
 * @param entity original composed entity part
 * @param fieldName entity-composed field name
 * @param value the entity field value
 * @private
 */
export const _updatedEntityFieldValue: (entity: Entity, fieldName: ID, value: EntityFieldValue) => EntityFieldValue = (
  entity,
  fieldName,
  value
) => {
  const [fieldNamePart, ...fieldNameRest] = fieldName.split('.');
  log.debug('_updatedEntityFieldValue', {fieldName, value, entity, fieldNameRest, fieldNamePart});
  if (!fieldNamePart) {
    log.debug('_updatedEntityFieldValue no fieldNamePart', {fieldName, value, entity, fieldNameRest, fieldNamePart});
    return value;
  }
  const {isArrayItemField, arrayFieldName, arrayFieldIndex} = _parseArrayItemFieldName(fieldNamePart);
  const subEntity = isArrayItemField
    ? (() => {
        const subEntityArray = [...(_subEntityAt(arrayFieldName!, entity) as Entity[])];
        log.debug('Array item update', {isArrayItemField, arrayFieldName, arrayFieldIndex, subEntityArray});
        subEntityArray[arrayFieldIndex!] = _updatedEntityFieldValue(
          _subEntityAt(fieldNamePart, entity) || {},
          fieldNameRest.join('.'),
          value
        ) as Entity;
        return subEntityArray;
      })()
    : fieldNameRest.isEmpty()
    ? isEntity(value)
      ? value
      : Array.isArray(entity[fieldNamePart])
      ? Array.isArray(value)
        ? value
        : isString(value)
        ? [value]
        : (() => {
            log.error('An Array entityField must be of string type in _updatedEntityFieldValue', {
              fieldName,
              value,
            });
            return 'Invalid value';
          })()
      : value
    : _updatedEntityFieldValue({...(entity[fieldNamePart] as Entity)}, fieldNameRest.join('.'), value);

  return {...entity, [`${isArrayItemField ? arrayFieldName : fieldNamePart}`]: subEntity};
};

const doCorrectPeriod = (
  entity: Entity & PeriodI,
  updatedFieldName: string,
  setPreference: (key: string, value: EntityFieldValue) => void
) => {
  if (
    (updatedFieldName === START_DATE_FIELD_ID ||
      updatedFieldName === START_TIME_FIELD_ID ||
      updatedFieldName === END_DATE_FIELD_ID ||
      updatedFieldName === END_TIME_FIELD_ID) &&
    !isValid(entity)
  ) {
    let fieldToAdjust:
      | typeof START_DATE_FIELD_ID
      | typeof START_TIME_FIELD_ID
      | typeof END_DATE_FIELD_ID
      | typeof END_TIME_FIELD_ID;
    let fieldToAdjustValue: FormattedDateOrNull | FormattedTimeOrNull | null;

    if (entity.startTime && entity.endTime && entity.startTime > entity.endTime) {
      // If the start date or end date fields was updated
      if (updatedFieldName === START_DATE_FIELD_ID || updatedFieldName === END_DATE_FIELD_ID) {
        fieldToAdjust = updatedFieldName === START_DATE_FIELD_ID ? END_DATE_FIELD_ID : START_DATE_FIELD_ID;
        var date = new Date(entity[updatedFieldName] as string);
        date.setDate(fieldToAdjust === START_DATE_FIELD_ID ? date.getDate() - 1 : date.getDate() + 1); // Move start or end date one day to make entity valid
        fieldToAdjustValue = date.toISOString().split('T')[0];
      } else {
        fieldToAdjust = updatedFieldName === START_TIME_FIELD_ID ? END_TIME_FIELD_ID : START_TIME_FIELD_ID;
        fieldToAdjustValue = entity[updatedFieldName] || null;
      }
    } else {
      fieldToAdjust = updatedFieldName === START_DATE_FIELD_ID ? END_DATE_FIELD_ID : START_DATE_FIELD_ID;
      fieldToAdjustValue = entity[updatedFieldName] || null;
    }

    setPreference(fieldToAdjust, fieldToAdjustValue);

    return _updatedEntityFieldValue(JSON.parse(JSON.stringify(entity)), fieldToAdjust, fieldToAdjustValue) as Entity;
  } else {
    return entity;
  }
};

export const useFormState: (props: FormStateOptions) => FormState = (props) => {
  const {fixedFields, correctPeriod = true, useUrlParams, preferenceFieldNames, validators = {}} = props;
  // const history = useHistory();
  const navigate = useNavigate();
  const urlLocation = useLocation();
  const urlParams = !useUrlParams ? {} : AppRoutes.parseQuerystring(urlLocation.search);
  const {preferences, setPreference} = usePreferences(preferenceFieldNames);
  const composedInitialEntity = composeFormStateEntity({
    initialEntity: props.initialEntity,
    preferences,
    urlParams,
    fixedFields,
  });

  const [initialEntity, setInitialEntity] = useState(composedInitialEntity);
  const [entity, setEntity] = useState(initialEntity);
  log.debug('render', {props, entity, initialEntity});

  useEffect(() => {
    clearErrors();
  }, []);

  useEffect(() => {
    if (useUrlParams) {
      log.debug('updating location parameters with entity fields', entity);
      navigate(
        AppRoutes.appendQuerystring(urlLocation.pathname, entity, requiredInUrlParams({preferences, fixedFields})),
        {replace: true}
      );
    }
  }, [entity]);

  if (!deepEqual(initialEntity, composedInitialEntity)) {
    log.debug('updating initialEntity due to changes on formStateOptions', {initialEntity, composedInitialEntity});
    setInitialEntity(composedInitialEntity);
    setEntity(composedInitialEntity);
  }

  const [isEditing, setIsEditing] = useState(props.isEditing);
  // const [isDirty, setIsDirty] = useState(false);
  const {putError, findError, clearError, clearErrors} = useErrors();
  const onCancel = () => {
    setEntity(initialEntity);
    setIsEditing(false);
    // setIsDirty(false);
    clearErrors();
  };
  const onSaved = (entity: Entity) => {
    setEntity(entity);
    setIsEditing(false);
    // setIsDirty(false);
  };
  const onEdit = () => setIsEditing(true);
  const onChange = (fieldName: ID) => (newValue: EntityFieldValue) => {
    const value = newValue === '' ? null : newValue;
    log.debug('onChange', fieldName, value);
    clearError(fieldName);

    setEntity((entity) => {
      let updatedEntity = _updatedEntityFieldValue(JSON.parse(JSON.stringify(entity)), fieldName, value) as Entity;
      updatedEntity = !correctPeriod ? updatedEntity : doCorrectPeriod(updatedEntity, fieldName, setPreference);
      return updatedEntity;
    });
    // setIsDirty(true);

    setPreference(fieldName, value);
  };

  function entityAs<T>(): T {
    return entity as unknown as T;
  }

  const validate = () => {
    clearErrors();
    let isValid = true;
    Object.keys(validators).forEach((fieldId) => {
      let fieldValidators = validators[fieldId];
      const field = entity[fieldId];
      log.debug('validating', {entity, fieldValidators, fieldId, field});

      if (!Array.isArray(fieldValidators)) fieldValidators = [fieldValidators];

      fieldValidators.forEach(({onValidate, regex, error}) => {
        const validatorFn = onValidate ? onValidate : field != undefined && regex?.test.bind(regex);

        if (validatorFn && !validatorFn(field)) {
          isValid = false;
          putError(fieldId, error);
        }
      });
    });
    return isValid;
  };

  return {
    initialEntity,
    entity,
    entityAs,
    subEntityAt: (fieldPath: string) => _subEntityAt(fieldPath, entity),
    isEditing,
    isCreating: initialEntity?.id === null,
    onSaved,
    onCancel,
    onEdit,
    onChange,
    findError,
    putError,
    clearErrors,
    validate,
  };
};

//  exported for Test ---

interface ComposableEntityParts {
  initialEntity?: Entity;
  preferences?: Entity;
  urlParams?: Entity;
  fixedFields?: Entity;
}

export const composeFormStateEntity: (parts: ComposableEntityParts) => Entity = ({
  initialEntity,
  preferences,
  urlParams,
  fixedFields,
}) => {
  return {...initialEntity, ...preferences, ...urlParams, ...fixedFields};
};

interface UrlPersistedCandidates {
  preferences?: Entity;
  fixedFields?: Entity;
}

export const requiredInUrlParams: (parts: UrlPersistedCandidates) => string[] = ({
  preferences = {},
  fixedFields = {},
}) => {
  const array = Object.keys(preferences).filter((preferenceFieldName) => preferences[preferenceFieldName] !== null);
  Object.keys(fixedFields).forEach((fieldName) => {
    array.includes(fieldName) || array.push(fieldName);
  });
  log.debug(`alwaysInUrlParams`, array);
  return array;
};

interface ParsedArrayItemField {
  isArrayItemField: boolean;
  arrayFieldName?: string;
  arrayFieldIndex?: number;
}

const arrayFieldSubPathRegexp = /(\w+)\[(\d+)\]$/;

export const _parseArrayItemFieldName: (subFieldPath: string) => ParsedArrayItemField = (subFieldPath) => {
  const arrayMatchResult = subFieldPath?.match(arrayFieldSubPathRegexp);
  const [isArrayItemField, arrayFieldName, arrayFieldIndex] = arrayMatchResult || [];
  return {
    isArrayItemField: !!isArrayItemField,
    arrayFieldName: !!arrayMatchResult ? arrayFieldName : undefined,
    arrayFieldIndex: !!arrayMatchResult ? parseInt(arrayFieldIndex) : undefined,
  } as ParsedArrayItemField;
};

export function _subEntityAt<T = EntityFieldValue>(fieldPath: string, entity: Entity): T {
  const _subEntity =
    !fieldPath || fieldPath.length == 0
      ? entity
      : fieldPath.split('.').reduce((subEntity: Entity, subFieldPath) => {
          const {isArrayItemField, arrayFieldName, arrayFieldIndex} = _parseArrayItemFieldName(subFieldPath);
          return (
            subEntity &&
            subFieldPath &&
            (isArrayItemField
              ? (subEntity[arrayFieldName!] as EntityFieldValue[])[arrayFieldIndex!]
              : subEntity[subFieldPath])
          );
        }, entity);
  log.trace('_subEntityAt', {fieldPath, entity, _subEntity});
  return _subEntity as unknown as T;
}
